import React from 'react';
import { Helmet } from 'react-helmet'

const DroitsText = (props) => (
    <>
        <Helmet>
            <title>Vos droits et Obligations - Parkmatch</title>
            <meta name="description" content="Vos droits et Obligations sur Parkmatch." />
        </Helmet>
        <div className="col-md-8 offset-md-2 pt-5">

<h2>Vos obligations</h2>

<h3>Etes-vous un particulier ou un professionnel ?</h3>

<p>
Il est important de noter que certaines activités pourraient vous classer en tant que professionnel, notamment si :<br /><br />

- Vous achetez des articles dans le but de les revendre, plutôt que pour votre usage personnel ;<br />
- Vous vendez des articles que vous avez fabriqués vous-même ;<br />
- Vous effectuez des ventes ou des prestations de services ou de locations fréquentes et en grand volume ;<br />
- Vos ventes ou vos prestations de services ou vos locations vous procurent des bénéfices significatifs et contribuent à vos revenus.<br /><br />

Si vous hésitez sur votre statut de professionnel ou de particulier, nous vous encourageons à consulter les lois et réglementations applicables qui peuvent vous aider à clarifier votre situation.

Les annonceurs professionnels ont interdiction formelle de communiquer sur Parkmatch.

Tout abus sera sanctionné par la suppression immédiate des annonces concernées, la suppression du compte et la transmission des annonces du professionnel au Service Protection Économique du Consommateur.

</p>

<h3>Annonceur particulier</h3>

<h4>Déclarer vos revenus générés sur notre site</h4>
<p>
Selon l'article 87 de la loi de finances de 2016, il est important de rappeler que vos activités sur notre site peuvent vous soumettre à l'obligation de payer l'impôt sur le revenu.<br /><br />

Il est de votre responsabilité de déclarer à l'administration fiscale les revenus générés par ces activités.<br /><br />

Nous tenons également à vous informer des risques associés à la fraude fiscale, qui incluent :<br /><br />

Un redressement fiscal pour régulariser les montants dus, incluant des pénalités de retard.<br />
Des amendes forfaitaires variant de 1,5% à 5% des montants non déclarés.<br />
Des sanctions pénales comprenant :<br />
Une peine de prison pouvant aller jusqu'à cinq ans, sans compter les circonstances aggravantes.<br />
Une amende pouvant atteindre 500 000 euros, sans compter les circonstances aggravantes.<br />
L'interdiction d'exercer certaines professions libérales ou de diriger une entreprise.<br />
La perte de droits civiques, civils et de famille.<br />
Des peines de confiscation.<br />
Pour obtenir davantage d'informations, nous vous invitons à visiter le site de la <a href="https://www.impots.gouv.fr/portail/node/10841">Direction Générale des Finances Publiques</a>.
</p>


<h2>Vos droits</h2>

<h3>Utilisateur</h3>

<p>
    Comme utilisateur individuel de notre site, vous possédez des droits concernant le traitement de vos données personnelles. Nous vous encourageons à les examiner dans nos conditions générales d'utilisation.<br /><br />

Si vous êtes un annonceur professionnel sur notre site et que vous avez déjà entrepris une démarche écrite auprès de CGMATCH sans parvenir à résoudre un litige, vous avez l'opportunité de faire appel au Médiateur des entreprises pour tenter de trouver une solution.
</p>

<h3>Acheteur / Client</h3>

<h4>Votre droit de rétractation</h4>

<p>
</p><p>Conformément aux articles L221-18 et suivants du Code de la consommation, et
notamment aux articles L221-28 1° et L221-28 3 : <br />
Vous bénéficiez d'un droit de rétractation. Vous pouvez exercer ce droit, sans avoir à justifier de motifs ni à payer
de pénalités à l'exception, le cas échéant, des frais de retour, dans un délai de quatorze (14) jours à compter
du lendemain de sa Commande.<br />
Vous pouvez exercer votre droit de rétractation avant l’expiration du délai précité soit depuis son Interface de
gestion via le formulaire prévu à cet effet, soit en complétant et en adressant à CGMATCH par courrier
recommandé avec accusé de réception, le formulaire de rétractation ci-dessous ou toute autre déclaration,
dénuée d'ambiguïté, exprimant sa volonté de se rétracter.</p>

<p>Formulaire de rétractation<br />
« A l'attention de CGMATCH SAS, 6 bis, rue de la Paroisse, GALAXY, 78000 Versailles – France :<br />
Je / nous (*) vous notifie/notifions (*) par la présente ma/notre (*) rétractation du contrat portant sur la vente
du bien (*) / pour la prestation de services (*) ci-dessous :<br />
Commandé(e) le (*) / reçu(e) le (*) : Nom du (des) consommateur(s) :<br />
Adresse du (des) consommateur(s) :<br />
Signature du (des) consommateur(s) (uniquement en cas de notification du présent formulaire sur papier) :<br />
Date :<br /><br />
(*) Rayez la mention inutile. »<br /><br />
Le droit de rétractation, s'il a été valablement exercé, vous permet d'obtenir le remboursement des
biens et Services concernés, déduction faite d’un montant proratisé à proportion du nombre de jours
pendant lesquels lesdits biens et Services ont été mis à votre disposition.<br />
Vous ne pouvez pas exercer votre droit de rétractation dans les cas prévus à l’article L221-28 du Code de la
Consommation, notamment pour les Services pleinement exécutés avant la fin du délai de rétractation ou
pour les biens et Services confectionnés selon les spécifications du consommateur ou nettement
personnalisés. Dans ce cas, vous en serez informé au moment de la Commande et serez invité à renoncer à votre
droit de rétractation.</p>
<p></p>

<h4>Votre droit de recourir à la médiation</h4>

<p>Si vous rencontrez un problème avec un professionnel qui vous a offert un service via notre site, la médiation de la consommation est une option à votre disposition.<br /><br />

Avant de faire appel à un médiateur, il est nécessaire d'avoir tenté de régler le différend directement avec le professionnel. Si ces efforts n'ont pas permis de trouver une solution, vous pouvez alors contacter un médiateur de la consommation.<br /><br />

La tâche du médiateur est de faciliter la résolution amiable du conflit entre consommateurs et professionnels.<br /><br />

Pour trouver les coordonnées des médiateurs compétents pour votre cas, référez-vous aux documents commerciaux du professionnel (comme les devis, factures, ou les conditions générales de vente) ou consultez son site web.<br /><br />

Pour obtenir davantage d'informations sur la médiation de la consommation, visitez le site : <a href="https://www.economie.gouv.fr/mediation-conso">https://www.economie.gouv.fr/mediation-conso</a></p>

<p>


Conformément à l'article L. 612-1 du Code de la consommation, le consommateur, sous réserve de l'article L.612.2 du code de la consommation, a la faculté d'introduire une demande de résolution amiable par voie de médiation, dans un délai inférieur à un an à compter de sa réclamation écrite auprès du professionnel.<br />
CGMATCH a désigné, par adhésion enregistrée sous le numéro 52710/VM/2403 la SAS Médiation Solution comme entité de médiation de la consommation.<br />
Pour saisir le médiateur, le consommateur doit formuler sa demande :<br />
- Soit par écrit à : Sas Médiation Solution - 222 chemin de la bergerie 01800 Saint Jean de Niost<br />
- Soit par mail à : contact@sasmediationsolution-conso.fr<br />
- Soit en remplissant le formulaire en ligne intitulé « Saisir le médiateur » sur le site https://www.sasmediationsolution-conso.fr<br />
Quel que soit le moyen de saisine utilisé, la demande doit impérativement contenir :<br />
- Les coordonnées postales, téléphoniques et électroniques du demandeur,<br />
- Le nom, l'adresse et le numéro d'enregistrement chez Sas Médiation Solution, du professionnel concerné,<br />
- Un exposé succinct des faits,<br />
- Copie de la réclamation préalable,<br />
- Tous documents permettant l'instruction de la demande (bon de commande, facture, justificatif de paiement, etc.)<br />
</p>

        </div>
    </>
);

export default DroitsText
