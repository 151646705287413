import React from 'react'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { FaHandshake } from 'react-icons/fa';

const AccessPanel = () => (
  <Paper className="mx-sm-5 p-4 mt-4">
    <Grid container>
      <Grid xs={12} item style={{ justifyContent: 'center' }}>
        <Typography variant="h5" color="primary" gutterBottom>
          Remise d'accès
        </Typography>
      </Grid>
      <Grid xs={12} container item style={{ justifyContent: 'space-between' }}>
        <Typography variant="body1" gutterBottom>
          <FaHandshake size={35} className="mr-3" />
          Main propre
        </Typography>
        <Link to="/trouver-un-parking">
          <Button className="no-outline" style={{ textTransform: 'none' }} color="primary">
            Comment ça marche?
          </Button>
        </Link>
      </Grid>
    </Grid>
  </Paper>
)

export default AccessPanel
