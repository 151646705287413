import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import { FaHandshake } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Remote from 'public/assets/img/remoteParkmatch.jpg';
import GLOBAL from 'src/stylesheet';
import { COLORS } from 'src/stylesheet/colors'

import Button from 'src/components/Button';
import OpenVideo from 'src/components/openVideo';
import EmojiSection from 'src/components/EmojiSection'
import QuoteParagraph from './containers/QuoteParagraph'
import PublicPage from 'src/templates/PublicPage';

import Parking from 'public/assets/img/parking.png';

const EMOJI_ELEMENTS = [
  {
    emoji: "🕊",
    title: "Des frais clairs",
    text: "15% partagés entre le propriétaire et le locataire dans le cas d'une location ponctuelle et 10% dans le cas d'une location mensuelle"
  }
]

class RentMyParking extends React.Component {

  render() {
    return (
      <PublicPage navbarPush
        metaTitle="Gérez la location de votre parking en ligne avec ParkMatch"
        metaDescription="La location de parking avec ParkMatch est sécurisée. Louez votre parking, garage ou box en toute sérénité et sans paperasse.">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 py-5">
            <h1 className="text-center">Pourquoi mettre mon parking, garage ou box en location sur <span style={{ color: COLORS.green }}>Parkmatch</span>?</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-8 offset-md-1 col-lg-5 offset-lg-3 px-5 mb-4">
            <QuoteParagraph maxWidth="300">Parkmatch est un service de location de parking entre particuliers.</QuoteParagraph>
          </div>
          <div className="col-md-3 col-lg-4">
            <img className="d-none d-md-block" src={Parking} style={{ width: 120, marginBottom: 20, marginLeft: 15 }} alt="parking" />
          </div>
        </div>

        <div style={{ backgroundImage: GLOBAL.gradientGray }} className="row d-flex justify-content-center pb-5 pt-3">
          <EmojiSection elements={EMOJI_ELEMENTS} />
        </div>

        <div className="row">
          <div className="col-12 pb-5">
            <h2>Comment donner l'accès au locataire ?</h2>
            <div style={{ fontFamily: 'BariolLight', fontSize: 26, color: COLORS.green, textAlign: 'center' }}>La solution ParkMatch</div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 p-0 mb-4">
            <div className=" m-auto rounded shadow" style={{ width: 275, backgroundColor: 'white' }}>
              <div className="rounded-top" style={{ width: '100%', height: 7, backgroundImage: GLOBAL.gradientBlue }} />
              <div style={{ height: 180, borderBottom: `solid 1px ${COLORS.green}` }} className="d-flex flex-row align-items-center justify-content-around w-100">
                <div style={{ width: 60, fontSize: 18 }}>De main à main</div>
                <div><FaHandshake size="40" color={COLORS.iconsGray} /></div>
              </div>
              <div className="p-3 d-flex flex-column justify-content-center" style={{ textAlign: 'center', height: 320, fontSize: '1.1em' }}>
                <div className="mb-2">
                  Rencontrez le propriétaire, et si le parking vous convient, il vous remettra les accès au parking (clé/télécommande/badge) en échange de votre code de confirmation.
                </div>
                <div className="mb-2">
                  La location démarre dès validation du code par le propriétaire.
                </div>
                {/* <div style={{ color: COLORS.green, textDecoration: 'underline' }}>Procédure détaillée</div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 mt-3 d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: 20 }}>
          <h2 className="p-2" style={{ textAlign: 'center' }}>Prêt à décoller avec nous ? <span role="img" aria-label="Rocket">🚀</span></h2>
          <Link to="/add" style={{ width: 250, height: 55 }}><Button fontSize={'1.1em'} resp={true} text="C'est parti !" /></Link>
          {/*<a href="https://docs.google.com/forms/d/e/1FAIpQLSeKaVlzjVXLbQFrfEoJWsFSklVFs9ZOFeC-2495gRYkip1puQ/viewform?usp=sf_link" className="my-4" rel="no-follow noopener noreferrer" target="_blank">Non, je reste ici</a>*/}
        </div>

        <div className="p-2 mt-3 d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: 20 }}>
          <h2 className="p-2" style={{ textAlign: 'center' }}>Garantie & Assurance</h2>
          <p>Parkmatch ne fournit aucune garantie ni assurance.</p>
        </div>

        <div className="p-2 mt-3 d-flex flex-column justify-content-center align-items-center" style={{ marginBottom: 20 }}>
          <h2 className="p-2" style={{ textAlign: 'center' }}>Modalités de règlement des litiges</h2>
          <p>En cas de litige entre un conducteur et un propriétaire suite à une réservation effectuée sur Parkmatch les parties sont invitées à intégrer Parkmatch dans les discussions (contact@parkmatch.eu).</p>
          <p>Parkmatch participera à fournir tout document ou information permettant aux parties un règlement amiable du litige.</p>
          <p>
Conformément à l'article L. 612-1 du Code de la consommation, le consommateur, sous réserve de l'article L.612.2 du code de la consommation, a la faculté d'introduire une demande de résolution amiable par voie de médiation, dans un délai inférieur à un an à compter de sa réclamation écrite auprès du professionnel.<br />
CGMATCH a désigné, par adhésion enregistrée sous le numéro 52710/VM/2403 la SAS Médiation Solution comme entité de médiation de la consommation.<br />
Pour saisir le médiateur, le consommateur doit formuler sa demande :<br />
- Soit par écrit à : Sas Médiation Solution - 222 chemin de la bergerie 01800 Saint Jean de Niost<br />
- Soit par mail à : contact@sasmediationsolution-conso.fr<br />
- Soit en remplissant le formulaire en ligne intitulé « Saisir le médiateur » sur le site https://www.sasmediationsolution-conso.fr<br />
Quel que soit le moyen de saisine utilisé, la demande doit impérativement contenir :<br />
- Les coordonnées postales, téléphoniques et électroniques du demandeur,<br />
- Le nom, l'adresse et le numéro d'enregistrement chez Sas Médiation Solution, du professionnel concerné,<br />
- Un exposé succinct des faits,<br />
- Copie de la réclamation préalable,<br />
- Tous documents permettant l'instruction de la demande (bon de commande, facture, justificatif de paiement, etc.)<br />
          </p>
        </div>

      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={RentMyParking} />;
