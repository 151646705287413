import React from 'react'
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';

const GetStar = ({ number }) => {
    if (number <= 5) { return <StarBorderIcon fontSize='small' /> }
    if (5 < number && number < 15) { return <StarHalfIcon fontSize='small' /> }
    if (number >= 15) { return <StarIcon fontSize='small' /> }
}

const StarRating = ({ percent }) => {
    const array = ['', '', '', '', '']
    return (
        array.map((e, index) =>
            <span key={index} style={{ verticalAlign: 'middle' }}>
                <GetStar number={(percent - index * 20)} />
            </span>
        )
    )

}

export default StarRating