import React from 'react'
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
// import { COLORS } from 'src/stylesheet/colors';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components';
import { MdImage } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Consumer, RENTAL_MODE } from 'src/services/store/SearchStore';

import StyledButton from 'src/components/StyledButton';
import Button from 'src/components/Button';
import checkDistance from 'src/utils/checkDistance';
import { COLORS } from 'src/stylesheet/colors';
import Icons from './icons';

class ListParkings extends React.Component {
  scrollToItem = () => {
    const { parkings, selectedId } = this.props
    const element = parkings.find(e => e.parking_id === selectedId)
    if (element && element !== undefined) {
      const ref = document.getElementById(element.parking_id.toString())
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  render() {
    const { parkings, onPressItem, selectedId, openAlertPanel, parkingPageParams } = this.props
    this.scrollToItem()
    return (
      <Paper id="list-parkings" square className="h-100 mh-100" style={{ overflowY: 'scroll', display: 'flex', flexDirection: 'column' }}>
        {parkings.length > 0 &&
          <List>
            {parkings.map(item => (
              <ListItem
                ref={ref => item.ref = ref}
                key={item.parking_id.toString()}
                selected={selectedId === item.parking_id}
                onClick={() => onPressItem(item)}
                button
                divider
                id={item.parking_id.toString()}
              >
                <ParkingRow parking={item} rentalMode={this.props.data.rentalMode} href={'/' + item.parking_url + parkingPageParams} />
              </ListItem>
            ))}
          </List>
        }
        {parkings.length === 0 &&
          <StyledDiv className="text-center">
            <span style={{ fontSize: 22 }}>
              Il n'y a aucun parking dans cette zone.
            </span>
            <span style={{ fontSize: 22 }}>
              Vous pouvez créer une alerte afin d'être avertit dès qu'un parking est disponible.
            </span>
            <AlertButton onClick={openAlertPanel} />
          </StyledDiv>
        }
        <Hidden smUp>
          {!parkings.length === 0 && <AlertButton onClick={openAlertPanel} />}
        </Hidden>
      </Paper>
    )
  }
}

const AlertButton = ({ onClick }) => (
  <StyledButton onClick={onClick} style={{ marginTop: 30, marginBottom: 30, marginLeft: 'auto', marginRight: 'auto' }}>
    Créer une alerte
  </StyledButton>
)

const ParkingRow = ({ parking, href, rentalMode }) => (
  <Grid container>
    <Grid item xs={12} direction="row" container>
      <Typography gutterBottom>
        {parking.address}
      </Typography>
      {parking.city && <Typography className="ml-1" color="textSecondary">| {parking.city}</Typography>}
    </Grid>
    <Grid item xs={3}>
      <ImageButton disabled style={{ backgroundColor: COLORS.lightGray }}>
        {parking.image ?
          <Image alt="complex" src={parking.image && parking.image.small} />
          : <MdImage size="50" color={COLORS.lightWhite} />
        }
      </ImageButton>
    </Grid>
    <Grid item xs={9} container className="pl-3">
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Icons name={parking.size} />
        <Typography color="textSecondary">à {checkDistance(parking.distance)}</Typography>
      </Grid>
      <Grid item xs={12} container justify="space-between" alignItems="center">
        <Link to={href}>
          <Button resp text="Voir le parking" />
        </Link>
        {rentalMode === RENTAL_MODE.oneshot &&
          <Typography color="textSecondary">{parking.total_price}€</Typography>
        }
        {rentalMode !== RENTAL_MODE.oneshot &&
          <Typography color="textSecondary">{parking.total_price}€ / mois</Typography>
        }
      </Grid>
    </Grid>
  </Grid>
)

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;
`

const ImageButton = styled(ButtonBase)`
  width: 110px;
  height: 90px;
  @media (max-width: 425px) {
    width: 75px;
    height: 60px;
  }
`

const Image = styled.img`
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
`

export default (props) => (
  <Consumer>
    {ctx => <ListParkings {...props} data={ctx} />}
  </Consumer>
);

