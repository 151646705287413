import axios from 'axios'

async function MapBoxGeocoder(query, types) {
    var uri = 'https://api.tiles.mapbox.com/geocoding/v5/' +
        'mapbox.places/' + encodeURIComponent(query) + '.json' +
        '?access_token=' +
        'pk.eyJ1IjoiY2xlbWVudC1mYXZpZXIiLCJhIjoiY2podWhzZjVzMG1udDN3anExYTM2cjFlNiJ9.ZDgRVJ8JuL_SiwtqdAukFw' +
        '&country=fr' +
        '&language=fr' +
        '&limit=6' +
        '&types=postcode,place,locality,neighborhood,address' +
        (types ? '&types=' + encodeURIComponent(types) : '');
    const res = await axios.create({ timeout: 35 * 1000 }).get(uri)
    return res
}

export default MapBoxGeocoder