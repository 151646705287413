import React from 'react';
import { Helmet } from 'react-helmet'

const CguText = (props) => (
    <>
        <Helmet>
            <title>Conditions générales d'utilisation - Parkmatch</title>
            <meta name="description" content="Conditions générales d'utilisation de la Plateforme: location de parking, garage et box entre particuliers." />
        </Helmet>
        <div className="col-md-8 offset-md-2 pt-5">
            <p><i>Version mise en ligne </i><i>janvier 2023</i></p>
            <p> </p>
            <p>VEUILLEZ LIRE ATTENTIVEMENT LES PRESENTES CONDITIONS D’UTILISATION DU SERVICE, CAR ELLES CONTIENNENT DES INFORMATIONS IMPORANTES CONCERNANT VOS DROITS, RECOURS ET OBLIGATIONS. ELLES INCLUENT DIFFERENTES LIMITATIONS ET EXCLUSIONS, UNE CLAUSE RELATIVE A LA MEDIATION DE LA CONSOMMATION AINSI QUE DES OBLIGATIONS RELATIVES AU RESPECT DES LOIS ET REGLEMENTS APPLICABLES.</p>
            <p>Les présentes Conditions Générales d’Utilisation ont pour objet d’encadrer l’accès et les modalités d’Utilisation de la Plateforme. Nous vous invitons à en prendre attentivement connaissance. Vous comprenez et reconnaissez que Parkmatch n’est partie à aucun accord, contrat ou relation contractuelle, de quelque nature que ce soit, conclu entre les Conducteurs et les Détenteurs de place.</p>
            <h2>1. Champ d’application et définitions</h2>
            <p><b> </b></p>
            <p>Les présentes Conditions Générales d’Utilisation s’appliquent aux services proposés par le site Internet PARKMATCH (<u>www.parkmatch.eu/fr</u>). Le service de stationnement proposé est édité par la société CGMATCH SAS, au capital de 8 000 euros, enregistrée au Registre du Commerce et des Sociétés de Versailles sous le numéro 894 743 210, et dont le siège social est sis au 6 B RUE DE LA PAROISSE, 78000 VERSAILLES (ci-après « Parkmatch »).</p>
            <p>Coordonnées : contact@parkmatch.eu - 01 80 84 44 20. Numéro de TVA : FR52894743210</p>
            <p>Dans les présentes Conditions Générales d’Utilisation, il est convenu que l’ensemble des termes commençant par une lettre capitale et listés ci-dessous sont définis de la manière suivante :</p>
            <p>·       « <b>Accès</b> » : désigne les données et signaux permettant l'ouverture d'un Emplacement de Parking, afin qu'un Utilisateur puisse y stationner;</p>
            <p>·       « <b>Annonce</b> » : désigne une annonce mettant à disposition un emplacement de Parking publié sur la Plateforme par un Détenteur de place ;</p>
            <p>·       « <b>CGU</b> » : désigne les présentes Conditions Générales d’Utilisations ;</p>
            <p>·       « <b>Compte</b> » : désigne la création d’un compte sur la Plateforme par l’Utilisateur tel que décrit à l’article 4.1;</p>
            <p>·       « <b>Conducteur » </b>: désigne tout Utilisateur d'un véhicule terrestre à moteur, préalablement inscrit et souhaitant réserver un emplacement de Parking sur la Plateforme ;</p>
            <p>·       « <b>Commission Parkmatch</b>» : désigne la Commission de Parkmatch afférente à la Réservation d'un Parking tels que définis à l'article 8.4 ;</p>
            <p>·        « <b>Détenteur de place</b> » : désigne tout Utilisateur, propriétaire ou locataire habilité à sous-louer un emplacement de Parking ayant enregistré son bien sur la Plateforme et ayant renseigné les principales caractéristiques de celle-ci en vue de le louer ;</p>
            <p>·       « <b>Durée Totale de la Réservation</b> »: désigne la durée totale de la Réservation, du début de la Réservation jusqu’au moment où l’Utilisateur la termine dans l’Application incluant les éventuels Dépassements et Prolongements ;</p>
            <p>·       « <b>Evaluation</b> » : désigne les notes et évaluations des Utilisateurs tels que définis à l'article 6.5</p>
            <p>·        « <b>Moyens d’identifications</b>» : désigne les moyens d’identification pour accéder au Service tel que défini à l’article 4.2 ;</p>
            <p>·       « <b>Plateforme</b> » : désigne la plateforme de réservation d’emplacement de Parkings ;</p>
            <p>·       « <b>Parking</b> » ou « <b>Emplacement de Parking</b> » : désigne un ouvrage destiné au stationnement de véhicule mis en ligne sur la Plateforme par un Détenteur de place et auquel le Conducteur a accès dans le cadre de son utilisation de la Plateforme ;</p>
            <p>·       « <b>Partage de la Réservation Récurrente</b> » : désigne la possibilité pour les Conducteurs de pouvoir partager leur Réservation Récurrente avec d’autres Utilisateurs dans les conditions précisées à l’article 8.3.3 des présentes CGU ;</p>
            <p>·       « <b>Pénalités de retard</b> » : désigne les modalités de pénalités décrites à l’article 8.7</p>
            <p>·       « <b>Réservation</b> » : désigne la période pendant laquelle un Conducteur a réservé un emplacement de Parking sur la Plateforme.</p>
            <p>·       <b>« Réservation Unique »: </b>désigne les Réservations pour une période supérieure à 24 heures et dont la durée et le terme ont été convenues à l’avance entre le Détenteur de Place et le Conducteur. </p>
            <p>·       <b>« Réservation Récurrente  »: </b>désigne les Réservations avec une périodicité mensuelle dont la durée n’a pas été convenue à l’avance.</p>
            <p>·       <b> </b>« <b>Service Client</b> »: désigne le service d'assistance disponible à l’adresse email support@parkmatch.eu ou en chat sur le Site Internet;</p>
            <p>·       « <b>Service Parkmatch</b> » ou « <b>Service</b>» : désigne l’ensemble des solutions qui permettent la mise en relation entre les Conducteurs et les Détenteurs de place, ainsi que l’Accès à l’emplacement de Parking ;</p>
            <p>·       « <b>Site Internet » </b>: désigne le site Internet Parkmatch accessible à l’adresse <u>www.parkmatch.eu</u> ;</p>
            <p>·       « <b>Tarif Horaire Applicable »</b>: désigne le tarif horaire applicable à la Réservation est le tarif horaire qui sera présenté au Conducteur sur la Plateforme Parkmatch avant d'effectuer une Réservation.</p>
            <p> </p>
            <p>·       « <b>Tarif Horaire Appliqué »</b>: désigne le tarif horaire appliqué à la Réservation</p>
            <p>avec les éventuelles réductions appliquées sur le Tarif Horaire Applicable tel que décrites à l'article 9.1.</p>
            <p> </p>
            <p>·       « <b>Télécommande »</b> : désigne des configurations de commande d’ouverture de portail automatique de Parking pour leur Réservation ;</p>
            <p>·        « <b>Utilisateur</b> » : désigne toute personne physique accédant au site Internet, regroupant à la fois les Détenteurs de place et les Conducteurs;</p>
            <h2>2. Objet</h2>
<p>
Parkmatch édite une solution de mise à disposition d’emplacements de stationnement entre particuliers, permettant aux Utilisateurs de stationner en ville en toute sérénité. Exploitant la technologie Parkmatch basée sur des innovations technologiques, Parkmatch construit un réseau intelligent de Parkings partagés dont l’accès et le paiement sont encadrés par Parkmatch, sans intervention sur le système existant.
</p>
<p>
Le service PARKADOM s'adresse exclusivement aux annonceurs particulier : toute personne physique, majeure, agissant exclusivement à des fins privées et non professionnelles.
</p>
<p>
Les annonceurs professionnels ont interdiction formelle de communiquer sur Parkmatch. Tout abus sera sanctionné par la suppression immédiate des annonces concernées, la 
suppression du compte et la transmission des annonces du professionnel au Service Protection Économique du Consommateur.
</p>
<p>
Il est bien précisé que Parkmatch n’est ni le propriétaire, ni le gestionnaire des Parkings référencés au sein de la Plateforme Parkmatch.
</p>
<p>
Parkmatch n’intervient pas dans les relations contractuelles entre les Conducteurs et les Détenteurs de place mais se limite à les mettre en relation en fonction des caractéristiques recherchées (emplacement géographique, durée, catégorie de véhicule, prix) et faciliter le paiement des loyers par l'intermédiaire de paiement Lemonway qui assure le prélèvement sur le moyen de paiement communiqué par le Conducteur et la réversion des loyers sur le compte bancaire du Detenteur de place.
</p>
<p>
Pour les Réservations Récurrentes et Uniques, Parkmatch permet aux Conducteurs de trouver un Parking adapté à leur besoin en fonction des Parkings disponibles publiés sur la Plateforme par les Détenteurs de Place. Les Utilisateurs se rencontrent physiquement pour procéder à la remise d’Accès aux Parkings.
</p>


            <p>Parkmatch édite une solution de mise à disposition d’emplacements de stationnement entre particuliers, permettant aux Utilisateurs de stationner en ville en toute sérénité. Exploitant la technologie Parkmatch basée sur des innovations technologiques, Parkmatch construit un réseau intelligent de Parkings partagés dont l’accès et le paiement sont encadrés par Parkmatch, sans intervention sur le système existant.</p>
            <p>Il est bien précisé que Parkmatch n’est ni le propriétaire, ni le gestionnaire des Parkings référencés au sein de la Plateforme Parkmatch.</p>
            <p>Parkmatch n’intervient pas dans les relations contractuelles entre les Conducteurs et les Détenteurs de place mais se limite à les mettre en relation en fonction des caractéristiques recherchées (emplacement géographique, durée, catégorie de véhicule, prix).</p>
            <p>Pour les Réservations Récurrentes et Uniques, Parkmatch permet aux Conducteurs de trouver un Parking adapté à leur besoin en fonction des Parkings disponibles publiés sur la Plateforme par les Détenteurs de Place. Les Utilisateurs se rencontrent physiquement pour procéder à la remise d’Accès aux Parkings.</p>
            <h2>3.Acceptation et respect des Conditions Générales d’Utilisation</h2>
            <h3>3.1 <u>Acceptation des CGU</u></h3>
            <p> </p>
            <p>Préalablement à son inscription sur la Plateforme, l’Utilisateur reconnaît expressément avoir pris connaissance des présentes CGU ainsi que des différents documents auxquelles elles font référence et les avoir acceptées sans restriction ni réserve dès lorsqu’il clique sur la case « J’accepte les Conditions Générales d’Utilisation ».</p>
            <p>Les présentes Conditions Générales s’appliquent pendant toute la durée de mise en ligne des Services de Parkmatch.</p>
            <h3>3.2 <u>Modifications des CGU</u></h3>
            <p> </p>
            <p>Parkmatch se réserve le droit, à sa seule discrétion, de modifier la Plateforme ou de modifier les présentes CGU à tout moment et sans notification préalable. Si nous procédons à des modifications des présentes Conditions, y compris des Commissions Parkmatch, nous publierons les CGU modifiées sur la Plateforme et nous vous adresserons une notification de la modification par e-mail. Nous actualiserons également la « Date de dernière mise à jour » figurant en haut des présentes CGU. En l’absence d’une communication de votre part  nous informant de votre refus des CGU modifiées dans un délai de trente (30) jours suivant la notification par email, votre accès ou utilisation de la Plateforme vaudra acceptation des CGU modifiées. Vous serez informé de votre droit de refus dans l’email de notification. Si vous n’acceptez pas les CGU modifiées, chacune des parties pourra mettre un terme au contrat avec effet immédiat, à la suite de quoi vous ne pourrez plus utiliser la Plateforme.</p>
            <p> </p>
            <h2><b>4.</b>             <b>Utilisation du Service</b></h2>
            <h3>4.1 <u>Inscription sur la Plateforme</u></h3>
            <p> </p>
            <p>L'inscription sur la Plateforme Parkmatch est nécessaire pour pouvoir utiliser le Service. A défaut, un Utilisateur ne peut ni publier une Annonce ni réserver un Parking sans avoir, au préalable, créé un Compte.</p>
            <p>Toute inscription sur la Plateforme par une personne mineure est strictement interdite. En accédant, utilisant ou vous inscrivant sur la Plateforme, l’Utilisateur déclare et garantie avoir plus de 18 ans.</p>
            <p>La création du Compte peut se faire soit :</p>
            <p>·       en remplissant l’ensemble des champs obligatoires figurant sur le formulaire d’inscription ;</p>
            <p>·       en se connectant, via notre Plateforme, à votre compte Facebook (ci-après, « Compte Facebook ») ou à votre compte Google (ci-après, « Compte Google »). En utilisant cette fonctionnalité l’Utilisateur reconnaît que Parkmatch aura accès, publiera sur la Plateforme et conservera certaines informations de votre Compte Facebook et/ou de votre Compte Google. Il est possible de supprimer à tout moment le lien entre le Compte Facebook  ou le Compte Google et le Compte, dans la rubrique « Profil ».</p>
            <p>Plus d'informations sur les informations collectées par Parkmatch sont disponibles dans la <a href="https://www.parkmatch.eu/informations-legales">Politique de confidentialité des données</a></p>
            <p>Par ailleurs, les informations supplémentaires suivantes doivent être fournies lors de la création du Compte :</p>
            <p>·       Pour l’utilisation du Service en tant que Conducteur :</p>
            <p>o   Le modèle et la marque de leur véhicule ;</p>
            <p>o   La catégorie du véhicule, représenté par un pictogramme ;</p>
            <p>o   La plaque d’immatriculation du véhicule ;</p>
            <p>o   Un numéro de téléphone mobile valide dont l’Utilisateur est titulaire;</p>
            <p>o   L’enregistrement d’une carte bancaire avec autorisation de prélèvement.</p>
            <p>Lors de l'enregistrement du numéro de téléphone mobile, l'Utilisateur devra confirmer la validité du numéro de téléphone au moyen d'un code transmis sur ce numéro de téléphone.</p>
            <p>Lors de l'enregistrement de la carte bancaire, Parkmatch procèdera à une vérification de la validité des informations fournies, aucune somme ne sera débitée lors de cette phase de vérification.</p>
            <p>·       Pour l’utilisation du Service en tant que Détenteur de place :</p>
            <p>o   L’adresse exacte du Parking ;</p>
            <p>o   Une description de l’emplacement du Parking ;</p>
            <p>o   Les dimensions de son emplacement de Parking, représentées par un pictogramme;</p>
            <p>o   Une ou plusieurs photographies de son Parking ;</p>
            <p>o   Un numéro de téléphone mobile dont l’Utilisateur est titulaire ;</p>
            <p>o   Pour effectuer des virements depuis son Compte Parkmatch un Relevé d’Identité Bancaire ainsi qu’une copie d’une pièce d’identité valide et les informations demandées à l’article 4.3.</p>
            <p>Pour pouvoir réserver un Parking les Conducteurs doivent remplir les conditions cumulatives suivantes :</p>
            <p>·       Etre titulaire d’un permis de conduire valable ;</p>
            <p>·       Etre inscrit sur la Plateforme sous sa véritable identité ;</p>
            <p>·       Etre titulaire d’un moyen de paiement électronique en cours de validité ;</p>
            <p>·       Etre titulaire d’une assurance de Responsabilité Civile en cours de validité ;</p>
            <p>·       Le véhicule doit disposer d’une assurance en cours de validité, souscrite dans une compagnie notoire ;</p>
            <p>·       Avoir fourni des informations vraies, exactes, complètes et actuelles.</p>
            <p>Pour pouvoir publier une Annonce sur la Plateforme, les Détenteurs de place doivent remplir les conditions cumulatives suivantes :</p>
            <p>·       Etre inscrit sur la Plateforme sous sa véritable identité ;</p>
            <p>·       Avoir renseigné dans son Annonce que des informations vraies, exactes, complètes et actuelles ;</p>
            <p>·       Ne proposer à la Réservation que des Parkings dont il est et demeure le propriétaire pendant toute la durée de la Réservation ou pour lequel il détient une autorisation expresse du propriétaire de les louer ;</p>
            <p>·       Ne violer aucun accord conclu avec des tiers, tels que les accords de conseil syndical, les règlements de copropriété ou tout autre contrat conclu avec des tiers.</p>
            <p>. Etre un annonceur particulier : une personne physique, majeure, agissant exclusivement à des fins privées et non professionnelles. Les annonceurs professionnels ont interdiction formelle de communiquer sur Parkmatch.</p>
            <p>Le Détenteur de Place assure et s'engage à effectuer l'ensemble des déclarations fiscales et de revenus liés à l'utilisation du Service.</p>
            <p>Dans l’hypothèse où l’Utilisateur fournirait des informations fausses ou non actuelles, Parkmatch pourrait immédiatement, sans préavis ni indemnité, suspendre et/ou résilier l’accès au Service. L’Utilisateur s’engage à fournir tous les justificatifs requis à Parkmatch, à première demande, et s’engage à mettre régulièrement à jour ses informations.</p>
            <p>Parkmatch se réserve la faculté d’accepter ou de refuser, sans motif et à sa seule discrétion, toute inscription sur la Plateforme et de suspendre et/ou supprimer le Compte de tout Utilisateur qui ne respecterait les présentes CGU.</p>
            <h3>4.2 <u>Moyens d’identification et confidentialité</u></h3>
            <p> </p>
            <p>A l’occasion de son inscription, l’Utilisateur s’engage à garder secret le mot de passe choisi, qui, avec son identification de connexion (adresse e-mail), constitue ses « Moyens d’Identification ».  Les Moyens d’Identification sont personnels, confidentiels, incessibles et intransmissibles.</p>
            <p>L’utilisateur s’engage à conserver ses Moyens d’Identification secret et à ne pas les divulguer sous quelque forme que ce soit. En cas de perte ou de divulgation des Moyens d’Identification, l’Utilisateur s’oblige à en informer Parkmatch sans délai. L’Utilisateur est seul responsable de l’utilisation faite de son Compte par un tiers, et ce, tant qu’il n’a pas expressément notifié Parkmatch de l’utilisation frauduleuse ou de la divulgation de ses Moyens d’Identification.</p>
            <h3>4.3 <u>Politique « Know Your Customer » (KYC)</u></h3>
            <p> </p>
            <p>Afin de procéder au virement des fonds collectés en utilisant le Service, dès que les sommes cumulées atteignent 20 euros, Parkmatch pourra procéder à une vérification d’identité  conformément aux dispositions en vigueur pour lutter contre le blanchiment de capitaux et de financement du terrorisme. Le cas échéant, le Détenteur de place devra communiquer dans les plus brefs délais à Parkmatch, une copie d’au moins deux documents parmi ceux présents dans la liste ci-dessous :</p>
            <p>·       Passeport</p>
            <p>·       Carte Nationale d’Identité (CNI)</p>
            <p>·       Permis de conduire</p>
            <p>·       Dernier avis d’imposition</p>
            <p>Tant que la procédure de vérification n’a pu aboutir, Parkmatch se réserve le droit de suspendre provisoirement les paiements à destination du Détenteur de place.</p>
            <h3>4.4 <u> Finalité de la Plateforme</u></h3>
            <p> </p>
            <p>Les Utilisateurs s’engagent à n’utiliser le Service et la Plateforme qu’à des fins non professionnelles</p>
            <p>Parkmatch se réserve la possibilité de suspendre votre compte ou de limiter les accès au Service des Utilisateurs qui utilisent la Plateforme et le Service à des fins professionnelles.</p>
            <h2><b>5.</b>             <b>Utilisation de la Plateforme</b></h2>
            <h3>5.1 <u>Publication des Annonces</u></h3>
            <p> </p>
            <p>Les Détenteurs de place peuvent publier des Annonces sur la Plateforme. Pour créer une Annonce, le Détenteur de place devra communiquer plusieurs informations sur le Parking concerné, y compris notamment, sur sa localisation, ses dimensions (représentées par un pictogramme), ses caractéristiques, sa disponibilité et son prix en fonction du prix conseillé par Parkmatch ainsi qu'une ou plusieurs photographie(s) du Parking.</p>
            <p>Pour pouvoir figurer parmi les Annonces sur la Plateforme, l’adresse physique doit être valide et le test d'accès au Parking doit avoir été réussi. Les Annonces seront rendus publiques sur la Plateforme. Le Détenteur de place reconnaît et accepte que l’ordre des résultats de recherche peut dépendre de nombreux facteurs y compris, notamment, des besoins du Conducteur, de la localisation du Conducteur et des Evaluations. Les Conducteurs pourront réserver un Parking sur la Plateforme en fonction des informations stipulées dans l’Annonce. </p>
            <h3>5.2 <u>Période de Disponibilités</u></h3>
            <p> </p>
            <p>Les Détenteurs de Place déterminent les périodes et les types de Réservations pour lesquels ils veulent rendre leurs Parkings disponibles sur la Plateforme.</p>
            <p>Les périodes de disponibilités des Parkings pour les Réservations Courte Durée, Unique et Récurrente sont modulables et précisées par les Détenteurs de Place. Les Détenteurs de Place peuvent rendre les Parkings disponibles en fonction de leur besoin.</p>
            <p> </p>
            <h3>5.3 <u>Mise en relation</u></h3>
            <p> </p>
            <p>Parkmatch fournit une Plateforme de mise en relation entre des Utilisateurs recherchant un Parking à louer et des Détenteurs de place souhaitant louer leur Parking. Parkmatch est un intermédiaire qui n'est pas partie à l'opération de location qui est réalisée entre les Utilisateurs.</p>
            <p>Tout Utilisateur accepte que tout recours ou responsabilité légale recherchée suite à des actes ou omissions d’autres Utilisateurs ou de tiers se limite à une réclamation à l’encontre des Utilisateurs ou autres tiers à l’origine du préjudice subi. Tout Utilisateur accepte de ne pas tenter de rechercher la responsabilité de Parkmatch, de ses dirigeants ou de son personnel, ni de tenter un recours auprès de Parkmatch, ses dirigeants ou de son personnel, pour de tels actes ou omissions.</p>
            <h2><b>6.</b>             <b>Réservations</b></h2>
            <h3>6.1 <u>Réservation avec remise en main propre</u></h3>
            <p> </p>
            <p>Lorsque les Utilisateurs procèdent à des Réservations avec remise physique des accès, le Détenteur de Place et le Conducteur ayant effectué la Réservation devront convenir entre eux des modalités de remise des accès physiques au Parking.</p>
            <p>Les Utilisateurs reconnaissent que Parkmatch n’intervient aucunement dans la remise des accès physiques entre les Utilisateurs. Si les Utilisateurs décident de se rencontrer physiquement, ils doivent prendre toutes les précautions nécessaires pour assurer leur sécurité.</p>
            <p>Parkmatch ne sera en aucun cas responsable de tout dommage, perte, vol ou incident de toute autre nature pouvant intervenir lors d’une rencontre physique entre les Utilisateurs.</p>
            <p> </p>
            <h3>6.1.1     <u>Réservation récurrente</u></h3>
            <p> </p>
            <p>Les Utilisateurs peuvent effectuer des réservations récurrentes d’une durée d’un mois sur la Plateforme Parkmatch (ci-après « <b>Réservation Récurrente</b> ».</p>
            <h3>6.1.1.1                  <u>Déroulé de la Réservation</u></h3>
            <p> </p>
            <p>Les Conducteurs sélectionnent une Annone  sur la Plateforme  et effectuent une demande de Réservation.</p>
            <p>Le Détenteur de Place reçoit une notification sur la Plateforme et / ou reçoit un courrier électronique l’informant qu’un Conducteur a effectué une demande de Réservation. Si le Détenteur de Place décide d’accepter la demande de réservation en cliquant sur « accepter la demande de réservation », il accepte de partager ces coordonnées avec le Conducteur afin qu’ils conviennent tous deux des modalités de remise des accès physiques permettant d’accéder au Parking.</p>
            <p>Le Conducteur reçoit alors un code d’activation (ci-après le « <b>Code d’Activation </b>») qu’il doit transmettre au Détenteur de place en échange de la remise  des Accès au Parking pour débuter la Réservation.</p>
            <p>Les Réservations Récurrentes sont renouvelées automatiquement tous les mois jusqu’à ce que le Conducteur ou le Détenteur de place décide d’y mettre fin, dans ce cas, la Réservation prendra fin conformément aux  modalités de fin des Réservations Récurrentes précisées ci-dessous.</p>
            <p> </p>
            <h3>6.1.1.2                  <u>Fin de la Réservation</u></h3>
            <p>Le Conducteur et le Détenteur de Place peuvent tout deux mettre fin à une Réservation avec un préavis d’un mois.</p>
            <p>Dans ce cas, un préavis de 30 jours démarre le jour de la demande de fin de la Réservation et la Réservation se terminera à la fin du mois de préavis. Pour le mois durant lequel la fin de la Réservation intervient, le Conducteur ne règlera que la période d’occupation effective du Parking jusqu’à la fin de la Réservation au jour du renouvellement de la Réservation Récurrente. Le montant dû pour ce dernier mois sera calculé au prorata du prix de Réservation mensuelle du Parking.</p>
            <p>Lors de la période de préavis, le Conducteur et le Détenteur de Place sont invités à se contacter afin de définir les modalités de restitution des accès au Détenteur de Place.</p>
            <p>En cas de difficultés relatives à la restitution des Accès à la fin du préavis ou de problèmes sur le Parking, le Détenteur de place dispose d’un délai de 48 heures pour signaler le problème sur la Plateforme, en sélectionnant l’option « J’ai un problème ».</p>
            <p>Lorsqu’un Détenteur de Place signale un problème au terme d’une Réservation Récurrente, après vérification par Parkmatch, le Conducteur peut se voir débiter une pénalité définie ci-après à l’article 8.7 afin de compenser la perte, le non restitution des Accès ou tout autre dommage au Parking.</p>
            <h3>6.1.1.3                  <u>Annulation de la Réservation</u></h3>
            <p> </p>
            <p>Le Conducteur peut annuler gratuitement une Réservation Récurrente avant son début et avant la transmission de son Code d’Activation au Détenteur de Place. Une fois la Réservation récurrente débutée, elle ne pourra pas être annulée et le Conducteur et le Détenteur de Place devront y mettre fin conformément à la procédure de fin de Réservation détaillée à l’article 6.1.1.2.</p>
            <p> </p>
            <p> </p>
            <h3>6.1.2     <u>Réservation unique</u></h3>
            <p> </p>
            <p>Les Utilisateurs peuvent procéder à des Réservations Uniques pour des durées supérieures à 24 heures sur la Plateforme.</p>
            <h3>6.1.2.1                  <u>Déroulé de la Réservation</u></h3>
            <p> </p>
            <p>Pour effectuer une Réservation Unique avec remise en main propre, le Conducteur détermine la durée souhaitée de la Réservation, il sélectionne ensuite l’Annonce choisie correspondante et effectue une demande de Réservation en sélectionnant « Effectuer une demande de réservation » sur la Plateforme afin d’effectuer la demande de Réservation.</p>
            <p>Le Détenteur de Place reçoit une notification sur la Plateforme et / ou reçoit un courrier électronique l’informant qu’un Conducteur a effectué une demande de Réservation. Si le Détenteur de Place décide d’accepter la demande de réservation en cliquant sur « accepter la demande de réservation », il accepte de partager ces coordonnées avec le Conducteur afin qu’ils conviennent tous deux des modalités d’accès au Parking ou de remise des accès physiques.</p>
            <p>En cas de difficultés liées à la récupération des Accès, au fonctionnement des Accès ou à l’accès au Parking, le Conducteur peut le signaler sur la Plateforme durant les 24 heures suivant le début de la Réservation. Dans ce cas, après vérification par l’équipe Parkmatch, la Réservation pourra être immédiatement terminée et le Conducteur remboursé.</p>
            <p>A défaut de signalement durant cette période de 24 heures, la Réservation est automatiquement considérée comme confirmée et l’ensemble des sommes dues pour la Réservation Unique deviennent exigibles et seront débitées du compte du Conducteur.</p>
            <h3>6.1.2.2                  <u>Fin de la Réservation Unique</u></h3>
            <p> </p>
            <p>La Réservation Unique prend fin automatiquement à la date et à l’heure planifiée de fin de Réservation.</p>
            <p>En cas de difficultés relatives à la restitution des Accès, à la fin du préavis ou de problèmes sur le Parking, le Détenteur de place dispose d’un délai de 48 heures à compter de la fin de la Réservation pour signaler le problème sur la Plateforme, en sélectionnant l’option « J’ai un problème ».</p>
            
            
            <h3>6.3 <u> Modalités de Paiement</u></h3>
            <p> </p>
            <p>La gestion du paiement des frais afférents aux Réservations est assurée par le partenaire de Parkmatch Lemonway. Lemonway assure en tant que prestataire de services de paiements, la gestion des comptes Lemonway des Utilisateurs Parkmatch ainsi que le mécanisme et les modalités de paiement lié aux Réservations effectuées sur la Plateforme par les Utilisateurs. . Ces paiements peuvent notamment concerner la Commission Parkmatch, le tarif applicable à la Réservation et/ou les éventuelles Pénalités de retard. Pour réaliser ces paiements, les Utilisateurs créeront un Compte sur la plateforme de notre partenaire Lemonway. Les paiements pourront être effectués par carte bancaire ou prélever directement sur le solde de votre compte chez notre partenaire Lemonway.</p>
            <p>Les paiements réalisés au moyen d'une carte bancaire ne peuvent être inférieurs à la somme de un (1) euro. Si la somme due pour ce paiement était inférieure à un euro, le reliquat sera crédité sur votre compte chez notre partenaire Lemonway.</p>
            <p>Plus d'informations relatives aux modalités de paiement sont disponibles à l'adresse suivante <a href="https://www.lemonway.com/conditions-generales-dutilisation/">https://www.lemonway.com/conditions-generales-dutilisation/</a>.</p>
            <p> </p>
            <h3>6.5 <u>Limitation de responsabilité</u></h3>
            <p> </p>
            <p>Le Détenteur de place reconnaît et accepte être le seul responsable de toutes les Annonces qu’il publie. En conséquence, le Détenteur de place déclare et garantie que toute Annonce qu’il publie :</p>
            <p>-       ne viole aucun accord conclu avec des tiers, tels que les accords de conseil syndical, les règlements de copropriété ou tout autre contrat conclu avec des tiers; et</p>
            <p>-       sera conforme à toutes les lois applicables, réglementation pouvant s’appliquer à tout Parking, obligations fiscales et ne violent aucun droit de tiers.</p>
            <p>Parkmatch se réserve la possibilité de supprimer toute Annonce ou tout contenu contraire aux présentes CGU ainsi qu’aux lois et règlementations en vigueur.</p>
            <p>Les Utilisateurs reconnaissent que Parkmatch n’agit pas en qualité d’assureur ou d’agent pour leur compte. Les Utilisateurs reconnaissent que Parkmatch exclut toute responsabilité concernant le respect par le Détenteur de place de tout accord ou obligation leur incombant, ainsi que des lois et règlementations applicables. Parkmatch ne pourra pas non plus être tenu responsable de la non véracité des informations fournies dans les Annonces, Parkmatch ne pouvant ni les modérer ni les vérifier a priori. Cependant, Parkmatch s'engage à retirer ou faire corriger toute information incorrecte contenue dans les Annonces après leur signalement sur la Plateforme.</p>
            <h2><b>7.</b>             <b>Disponibilité de la Plateforme et du Service</b></h2>
            <p>Parkmatch s’efforcera, dans la mesure du possible, de maintenir la Plateforme accessible 7 jours sur 7 et 24 heures sur 24. Néanmoins, l’accès à la Plateforme pourra être temporairement suspendu, sans préavis, en raison d’opérations techniques de maintenance et de mises à jour ou en raison de pannes ou de contraintes liées au fonctionnement des réseaux ou encore en cas de force majeure.</p>
            <p>Les Utilisateurs sont parfaitement informés que la Plateforme et le Service peuvent être suspendus à tout moment, sans préavis et sans qu’une quelconque indemnité ne soit due aux Utilisateurs, pour réaliser ces opérations de maintenance et de mises à jour. Parkmatch n’est en aucun cas responsable de la non-réalisation d’une Réservation en cas d’indisponibilité de la Plateforme et/ou du Service, y compris lorsqu’une telle indisponibilité résulte d’une intervention de Parkmatch sur Plateforme.</p>
            <p>Dans l’hypothèse où un dysfonctionnement ou une anomalie entraverait le bon fonctionnement de la Plateforme ou du Service, Parkmatch s’engage à procéder aux opérations nécessaires au rétablissement de la Plateforme ou du Service dans les meilleurs délais.</p>
            <p>En outre, Parkmatch se réserve le droit de modifier ou de suspendre, à sa seule discrétion, de manière temporaire ou permanente, tout ou partie de l’accès à la Plateforme ou de ses fonctionnalités, notamment, en cas d'utilisation frauduleuse ou abusive du système de Parrainage.</p>
            <p> </p>
            <h2>8.             <b>Conditions tarifaires</b></h2>
            <h2>8.1 <u>Tarif applicable à la Réservation Courte Durée</u></h2>
            <p> </p>
            <p>Le tarif applicable à la Réservation se compose des éléments suivants:</p>
            <p>-       Le Tarif Horaire Applicable à la Réservation;</p>
            <p>-       La Durée Totale de la Réservation, y compris la durée des éventuels Dépassements et/ou de Prolongements;</p>
            <p>-       L'éventuel Pénalité de retard;</p>
            <p>-       La Commission Parkmatch.</p>
            <p>Le Tarif Horaire Applicable à la Réservation sera présenté au Conducteur sur la Plateforme Parkmatch avant d'effectuer une Réservation.</p>
            <p>Le Tarif Horaire Applicable à la Réservation peut varier en fonction de différents facteurs, tel que l'heure de la Réservation, le nombre de demandes de Réservation, la durée de la Réservation, le nombre de Parkings disponibles au moment de la Réservation etc.</p>
            <p>Parkmatch affichera toujours de façon transparente le Tarif Horaire Applicable à la Réservation.</p>
            <p>Les Réservations sont comptabilisées par tranche d'une minute. La durée minimale d'une Réservation est fixée à une heure. Si un Conducteur termine la Réservation avant la fin de cette heure, l'intégralité de l'heure sera facturée. Au-dessus d'une heure, la facturation fonctionnera au temps consommé.</p>
            <p>Le Détenteur de Place recevra la totalité du montant de la Réservation effectuée sur son Parking à l'exception de la Commission Parkmatch (définie à l'article 9.4) et éventuellement de la Pénalité de retard telle que définie à l'article 9.5 ci-dessous.</p>
            <p>Pour les Réservations Courte Durée de plus de 4 heures, une réduction pourra être appliquée au Tarif Horaire Applicable et correspondra au Tarif Horaire Appliqué. Le Tarif Horaire Appliqué dépend la Durée Totale de la Réservation et est déterminé selon les critères non cumulables suivants:</p>
            <p>-       Réservation de plus de 4 heures : -20% sur le Tarif Horaire Applicable.</p>
            <p>-       Réservation de plus de 8 heures : -35% sur le Tarif Horaire Applicable.</p>
            <p>Ce tarif dégressif n’impacte aucunement le montant des Pénalités de Retard définies à l’article 9.5.</p>
            <h3>8.2 <u>Tarif applicable à la Réservation Unique</u></h3>
            <p> </p>
            <p>Pour les Réservations Uniques, le tarif applicable à la Réservation Unique est intégralement défini par le Détenteur de Place. Le tarif applicable est un tarif fixe et global applicable à l’ensemble de la durée de la Réservation (hors éventuel Dépassement, Prolongement ou Pénalité).</p>
            <p>Le Conducteur accepte le Tarif applicable à la Réservation lorsqu’il effectue sa Réservation Unique.</p>
            <p>Le Conducteur reste redevable de toute autre somme relative aux éventuels Dépassements, Prolongements et Pénalités.</p>
            <p> </p>
            <h3>8.3 <u>Tarif applicable à la Réservation Récurrente</u></h3>
            <p> </p>
            <p>Pour les Réservations Récurrentes, le tarif applicable à la Réservation Récurrente est intégralement défini par le Détenteur de Place. Le tarif applicable est un tarif fixe mensuel applicable à chaque période mensuelle de Réservation (hors éventuelle Pénalité).</p>
            <p>Le Conducteur accepte le Tarif applicable à la Réservation Récurrente lors de la demande de Réservation Récurrente et de sa confirmation.</p>
            <p>Les montants liés aux Réservations Récurrentes seront en toute hypothèse débités de manière mensuelle sur le compte du Conducteur, à la date de renouvellement de la Réservation Récurrente.</p>
            <p> </p>
            <h3>8.3.1     <u>Rémunération complémentaire pour le Partage de Réservation</u></h3>
            <p> </p>
            <p> </p>
            <h3>8.3.2     <u>Pour le Conducteur titulaire de la Réservation</u></h3>
            <p> </p>
            <p>Lorsque le Conducteur peut partager sa Réservation Récurrente avec d’autres Utilisateurs dans les conditions prévues à l’article 6.2.2.4 des Présentes CGU, le Conducteur pourra bénéficier d’une partie des revenus liés au partage de sa Réservation à hauteur de 65% du montant des Réservations Courte Durée, une fois la Commission Parkmatch déduite.</p>
            <h3>8.3.3     <u>Pour le Détenteur de Place acceptant le Partage de Réservation</u></h3>
            <p> </p>
            <p>Lorsque le Partage de la Réservation a été activé par le Détenteur de Place et mis en œuvre par le Conducteur titulaire de la Réservation Récurrente, le Détenteur de Place pourra bénéficier d’une partie des revenus liés au partage de la Réservation Récurrente à hauteur de 35% du montant de Réservation Courte Durée, une fois la Commission Parkmatch déduite.</p>
            <h3>8.4 <u>Commission Parkmatch et frais de services</u></h3>
            <p> </p>
            <h3>8.4.1     <u>Commission Parkmatch</u></h3>
            <p> </p>
            <p> </p>
            <p> </p>
            <p>Pour l’ensemble des Réservations, la Commission Parkmatch correspond à la part du montant de la Réservation que Parkmatch perçoit au titre de la Plateforme et notamment pour la mise en relation, la facilitation des Accès et pour la gestion de la Réservation et de la facturation</p>
            <p>La Commission Parkmatch est déduite avant le versement du montant total de la Réservation au Détenteur de Place.</p>
            <h3>8.4.2     <u>Frais de Services</u></h3>
            <p> </p>
            <p>Pour chaque Réservation, des frais de services seront appliqués et seront facturés au Conducteur effectuant la Réservation.</p>
            <p>Les frais de services correspondent aux frais engagés par Parkmatch pour la gestion de son service de mise en relation, de facilitation des Accès, de gestion des Réservations et de gestion de la facturation.</p>
            <p> </p>
            <h3>8.5 <u> Taxe d’habitation</u></h3>
            <p> </p>
            <p>Dans certaines situations, les Utilisateurs sont susceptibles d’être redevables de la taxe d’habitation dans les conditions des articles 1407 et suivants du Code Général des Impôts. Le cas échéant, les Utilisateurs s’engagent à s’acquitter de leurs obligations fiscales en la matière.</p>
            <h2><b>9.</b>             <b>Responsabilité</b></h2>
            <p>La Plateforme et le Service sont fournis en l’état. Parkmatch ne saurait être tenu responsable pour un défaut de fonctionnement de la Plateforme ou du Service, tel que notamment un défaut de disponibilité.</p>
            <p>L’Utilisateur étant regardé comme des consommateurs à l’égard de l’utilisation du Service, Parkmatch est responsable sur le fondement des dispositions légales applicables de la bonne exécution de ses obligations au titre des CGU, sauf en cas de manquements imputables à l’Utilisateur, soit aux cas de Force Majeure (tel que définis à l’article 11).</p>
            <p>L’Utilisateur  est le seul et unique responsable de l’utilisation qu’il fait de la Plateforme ou du Service.</p>
            <p>Parkmatch décline toute responsabilité pour tout litige, plainte, réclamation, contestation, poursuite, etc. de quelque nature que ce soit liés directement ou indirectement à l’utilisation du Service à des fins illicites ou en contravention de l’une quelconque des obligations incombant à l’Utilisateur au titre des présentes CGU. L’Utilisateur déclare donc accepter pleinement et sans condition toutes les obligations légales découlant de l’utilisation du Service. En conséquence, la responsabilité de Parkmatch ne pourra être recherchée à quelque titre que ce soit, notamment en cas de violation de lois ou règlements en vigueur applicables à l’Utilisateur.</p>
            <p>L’utilisateur reconnait être seul responsable de toute communication et /ou interaction avec d’autres utilisateurs de la Plateforme et/ou du Service y compris, notamment tout Conducteur ou Détenteur de place. L’utilisateur comprend et accepte que Parkmatch n’essaiera pas de vérifier les déclarations des Utilisateurs du Service. Parkmatch ne fait aucune déclaration ou garantie concernant le comportement des Utilisateurs de la Plateforme ou du Service.</p>
            <p>L’Utilisateur accepte de prendre des précautions raisonnables lorsqu’il communique entre en contact avec d’autres Utilisateurs. Plus spécifiquement dans l’éventualité ou des Utilisateurs décideraient de se rencontrer hors ligne ou en personne, que cette rencontre soit ou non organisée sur la Plateforme. Parkmatch exclut expressément toute responsabilité à cet égard.</p>
            <p> </p>
            <h2><b>10.</b>          <b>Force majeure</b></h2>
            <p>Parkmatch ne sera pas tenu responsable de tout retard ou inexécution, lorsque la cause du retard ou de l'inexécution serait due à la survenance d'un cas de force majeure tels que, outre ceux habituellement reconnus par la jurisprudence, le blocage des télécommunications, le blocage d’Internet, la panne du matériel diffusant le service, l’incendie, les dégâts des eaux, le blocage des moyens de transport ou d’approvisionnement, la grève totale ou partielle, ou tout autre événement au caractère imprévisible, irrésistible et extérieur.</p>

            <p>Chaque partie devra informer l'autre dans les meilleurs délais en cas de survenance d'un événement de force majeure l'empêchant d'exécuter tout ou parties de ses obligations</p>
            <p>Le cas de force majeure suspend les obligations nées des présentes conditions pendant toute la durée de son existence. Cependant si le cas de force majeure avait une durée d'existence supérieure à un (1) mois consécutif, il ouvrirait droit à la résiliation de plein droit des présentes conditions par l'une ou l'autre des parties quinze (15) jours après notification par tout moyen de cette décision.</p>
            <h2><b>11.</b>          <b>Politique de protection des données personnelles</b></h2>
            <p>La « Politique de Protection des Données » de même que les dispositions figurant aux « Mentions Légales », font partie intégrante des présentes conditions. Dès lors qu'il accepte les présentes conditions, l’Utilisateur déclare accepter également les termes de ces dernières.</p>
            <p>Dans le cadre de l’utilisation du Service et de la Plateforme, Parkmatch est amenée à collecter et traiter certaines données personnelles de ses Utilisateurs. En utilisant le Site ou la Plateforme, les Utilisateurs reconnaissent et acceptent le traitement de leurs données personnelles par Parkmatch conformément à la réglementation en vigueur et à la Politique de Confidentialité des Données</p>
            <p>Pour plus d’informations, la Politique de Protection des Données est disponible à l'adresse suivante: <a href="https://www.parkmatch.eu/informations-legales">https://www.parkmatch.eu/informations-legales</a></p>
            <p> </p>
            <p>la Plateforme utilise des cookies pour améliorer l'expérience utilisateur, faciliter la navigation, et mesurer la fréquentation de nos services. Si vous souhaitez vous opposer à l'utilisation des cookies, certaines rubriques du site risquent de ne pas fonctionner.</p>
            <h2><b>12.</b>          <b>Propriété Intellectuelle</b></h2>
            <h3>12.1       <u>Droits de propriété Intellectuelle</u></h3>
            <p> </p>
            <p>Parkmatch est titulaire de l’ensemble des droits de propriété intellectuelle afférents aux éléments textuels, visuels, sonores, vidéographiques, logiciels et bases de données ou de toute autre nature composant la Plateforme, notamment la marque Parkmatch, à l’exception des informations renseignées par les Utilisateurs. Parkmatch est l’unique propriétaire des droits de propriété intellectuelle sur les technologies utilisées. La Plateforme constitue une œuvre sur laquelle Parkmatch est le seul titulaire des droits de propriété intellectuelle.</p>
            <p>L’Utilisateur s’engage à ne pas porter atteinte aux droits de propriété intellectuelle de Parkmatch. Aucune fonctionnalité de la Plateforme, ne peut être utilisée par l’Utilisateur avec l’objet ou avec l’effet de porter atteinte aux droits de propriété intellectuelle attachés à la Plateforme et aux éléments qui le composent. L’utilisateur s’engage expressément  à ne pas reconstituer ou d’aider un tiers à reconstituer, à partir de la Plateforme ou de n’importe lequel de ses éléments un service visant à offrir directement ou indirectement, à titre gratuit ou onéreux, un service identique ou similaire et tout ou partie du Service. De même, il est formellement interdit de procéder à de l’ingénierie inversée (« retro-engineering ») pour toute autre fin que celle de l’interopérabilité du Service, de la Plateforme ou de n’importe lequel de ses éléments, avec une application tierce.</p>
            <h3>12.2       <u>Licence</u></h3>
            <p> </p>
            <h2>13.	Loi Applicable</h2>
            <p>Les présentes CGU sont soumises au droit français.</p>
            <p>En cas de litige notamment lié à l’interprétation ou l’exécution des présentes CGU, les parties s’engagent à rechercher une solution amiable, en particulier en contactant le Service Client.</p>
            <p>A la suite d’un échec d’une réclamation écrite de l'Utilisateur auprès du Service Client ou en l’absence de réponse du Service Client dans un délai raisonnable d’un (1) mois, l'Utilisateur peut recourir à tout mode alternatif de règlement des différends et notamment à une procédure de médiation gratuite pour l'Utilisateur en contactant le médiateur suivant :</p>
<p>Conformément à l'article L. 612-1 du Code de la consommation, le consommateur, sous réserve de l'article L.612.2 du code de la consommation, a la faculté d'introduire une demande de résolution amiable par voie de médiation, dans un délai inférieur à un an à compter de sa réclamation écrite auprès du professionnel.<br />
CGMATCH a désigné, par adhésion enregistrée sous le numéro 52710/VM/2403 la SAS Médiation Solution comme entité de médiation de la consommation.<br />
Pour saisir le médiateur, le consommateur doit formuler sa demande :<br />
- Soit par écrit à : Sas Médiation Solution - 222 chemin de la bergerie 01800 Saint Jean de Niost<br />
- Soit par mail à : contact@sasmediationsolution-conso.fr<br />
- Soit en remplissant le formulaire en ligne intitulé « Saisir le médiateur » sur le site https://www.sasmediationsolution-conso.fr<br />
Quel que soit le moyen de saisine utilisé, la demande doit impérativement contenir :<br />
- Les coordonnées postales, téléphoniques et électroniques du demandeur,<br />
- Le nom, l'adresse et le numéro d'enregistrement chez Sas Médiation Solution, du professionnel concerné,<br />
- Un exposé succinct des faits,<br />
- Copie de la réclamation préalable,<br />
- Tous documents permettant l'instruction de la demande (bon de commande, facture, justificatif de paiement, etc.)<br /></p>
            <h2>14. Rétractation</h2>

<p>Conformément aux articles L221-18 et suivants du Code de la consommation, et notamment aux articles L221-28 1° et L221-28 3 :</p>
<p>Le Client bénéficie d'un droit de rétractation. Il peut exercer ce droit, sans avoir à justifier de motifs ni à payer de pénalités à l'exception, le cas échéant, des frais de retour, dans un délai de quatorze (14) jours à compter du lendemain de sa Commande.</p>
<p>Le Client doit exercer son droit de rétractation avant l’expiration du délai précité soit depuis son Interface de gestion via le formulaire prévu à cet effet, soit en complétant et en adressant à CGMATCH par courrier recommandé avec accusé de réception, le formulaire de rétractation ci-dessous ou toute autre déclaration, dénuée d'ambiguïté, exprimant sa volonté de se rétracter.</p>

<p>Formulaire de rétractation</p>
<p>« A l'attention de CGMATCH SAS, 6 bis, rue de la Paroisse, GALAXY, 78000 Versailles – France :
Je / nous (*) vous notifie/notifions (*) par la présente ma/notre (*) rétractation du contrat portant sur la vente du bien (*) / pour la prestation de services (*) ci-dessous :
Commandé(e) le (*) / reçu(e) le (*) : Nom du (des) consommateur(s) :
Adresse du (des) consommateur(s) :
Signature du (des) consommateur(s) (uniquement en cas de notification du présent formulaire sur papier) :
Date :

(*) Rayez la mention inutile. »</p>

<p>Le droit de rétractation, s'il a été valablement exercé, permet au Client d'obtenir le remboursement des biens et Services concernés, déduction faite d’un montant proratisé à proportion du nombre de jours pendant lesquels lesdits biens et Services ont été mis à disposition du Client.</p>
<p>Le Client ne peut pas exercer son droit de rétractation dans les cas prévus à l’article L221-28 du Code de la Consommation, notamment pour les Services pleinement exécutés avant la fin du délai de rétractation ou pour les biens et Services confectionnés selon les spécifications du consommateur ou nettement personnalisés. Dans ce cas, le Client en est informé au moment de la Commande et est invité à renoncer à son droit de rétractation.</p>

        </div>
    </>
);

export default CguText
