import React from 'react';
import { Helmet } from 'react-helmet'

const ModalitesText = (props) => (
    <>
        <Helmet>
            <title>Modalités de référencement, déréférencement et classement</title>
            <meta name="description" content="Modalités de référencement, déréférencement et classement sur Parkmatch" />
        </Helmet>
        <div className="col-md-8 offset-md-2 pt-5">

<h2>I. Modalités de référencement et de déréférencement</h2>

<h3>Bonnes pratiques</h3>

<p>Lors du dépôt d'une annonce, il est impératif de respecter les directives suivantes :<br /><br />

<strong>Description de l'annonce :</strong><br /><br />

- Seules les annonces relatives à la mise en location d'un parking, garage ou box sont autorisées.<br />
- La description doit clairement définir le bien proposé. Les annonces avec un contenu général ne seront pas acceptées. Chaque annonce ne doit concerner qu'un seul bien.<br />
- Les photos ajoutées doivent uniquement illustrer le bien mis en location.<br />
- Les échanges de biens sont interdits.<br />
- L'utilisation de mots-clés est interdite.<br />
- Il est interdit de mentionner une marque dans la description ou le titre de l'annonce.<br />
- L'indication de labels doit se conformer à la législation en vigueur et être clairement mentionnée dans la description.<br />
<br />
<strong>Localisation de l'annonce :</strong><br /><br />

- Les biens doivent être annoncés dans la localité et à la localisation où ils se trouvent.<br />
<br />

<strong>Indication des prix :</strong><br /><br />

- Le prix total TTC doit être indiqué dans la section "Prix".<br /><br />

<strong>Doublons :</strong><br /><br />

- Il est interdit de publier plusieurs fois la même annonce sur le site.<br />
- Pour proposer un bien déjà listé sur le site, l'annonce précédente doit être retirée.<br />

Toute annonce ne respectant pas ces critères sera supprimée.<br />

Annonces émanant de professionnels :<br />

Les annonces publiées par des professionnels sont formellement interdites.<br />

Tout abus sera sanctionné par la suppression immédiate des annonces concernées, la suppression du compte et la transmission des annonces 
du professionnel au Service Protection Économique du Consommateur.</p>

<h3>Signaler une annonce</h3>

<p>Si vous rencontrez une annonce qui vous semble inappropriée ou qui viole nos directives, CGMATCH offre une option simple pour la signaler. Il suffit de nous la signaler par email à contact@parkmatch.eu.<br /><br />

Nous retirerons du site toute annonce qui, après vérification, s'avère ne pas respecter nos règles.</p>

<h2>II. Modalités de classement</h2>

<h3>Pages de résultats de recherche</h3>
<p>Les annonces sont classées par rapport à la distance (par ordre ascendant) calculée entre la localisation de recherche et la localisation de l'annonce.</p>

<h3>Pages d'Annonces</h3>
<p>Les annonces sont classées par rapport à la distance (par ordre ascendant) calculée entre la localisation de l'annonce en cours de visualisation et la localisation de l'annonce.</p>

<h3>Autres pages</h3>
<p>Les annonces sont classées par rapport à la distance (par ordre ascendant) calculée entre la localisation du centre de la zone de recherche en cours de visualisation et la localisation de l'annonce.</p>


        </div>
    </>
);

export default ModalitesText
