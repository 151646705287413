import React from 'react';
import PublicRoute from 'src/containers/PublicRoute';
import { withRouter } from 'react-router-dom'
// import FacebookLogin from 'react-facebook-login';
// import GoogleLogin from 'react-google-login';
// import { FaFacebookF } from 'react-icons/fa';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import PublicPage from 'src/templates/PublicPage';

import Api from 'src/services/api';
import { Consumer } from 'src/services/store';
import fetchApi from 'src/services/fetchApi';
import StyledButton from 'src/components/StyledButton'
import { validateEmail, checkPassword, checkError } from 'src/utils/checkAuth';
import ForgottenPassword from './components/ForgottenPassword'

import { COLORS } from 'src/stylesheet/colors';
import GLOBAL from 'src/stylesheet';

import './styles.css';

class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
    error: false,
    isLoading: false,
  }

  // componentDidMount() {
  //   if (this.props.data.isLogged && !this.props.checkout) {
  //     this.props.history.push('/account')
  //   }
  // }

  checkLogin = (e) => {
    e.preventDefault()
    const { email, password } = this.state;
    if (validateEmail(email) && checkPassword(password)) {
      this.setState({ error: false });
      this.login();
    } else {
      this.setState({ error: true, message: checkError("tototo", "tototo", password, email) })
    }
  }

  handleOnChange = (e, name) => {
    this.setState({ [name]: e.target.value })
  }

  login = () => {
    const { email, password } = this.state;
    const { checkout } = this.props;
    const location = window.location
    const userCredential = base64.encode(`${email}:${password}`);
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Basic ${userCredential}`,
      }
    };
    this.setState({ isLoading: true });
    return fetchApi('/connect', 'POST', { notification_token: null }, options).then((res) => {
      if (res.error) {
        if (res.error.statusCode === 401) {
          this.setState({
            error: true,
            message: 'Identifiants invalides',
            isLoading: false
          });
        } else {
          this.setState({ error: true, message: res.error.message, isLoading: false });
        }
      } else {
        Api.parkmatch.setAccessToken(res.token);
        localStorage.setItem('token', res.token);
        localStorage.setItem('auth', userCredential);
        this.props.data.handleChangeLogged(true);
        this.props.data.handleChangeUser(res);
        if (checkout) {
          return
        } else if (this.props.data.isAddingParking) {
          this.setState({ isLoading: false })
          this.props.history.push('/add');
        } else {
          this.setState({ isLoading: false })
          this.props.history.push('/account');
        }
      }
    });
  }

  isAddingParking = () => {
    const location = window.location
    if (location.state && location.state.add) {
      this.props.history.push({ pathname: '/register', state: { add: true } });
    } else {
      this.props.history.push('/register');
    }
  }

  renderForm = () => {
    const { error, email, password, message, isLoading } = this.state;
    return (
      <>
        <div className="form-group">
          <input value={email} onChange={(e) => this.handleOnChange(e, 'email')} type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Adresse email" required />
        </div>
        <div className="form-group" style={{ marginBottom: 0 }}>
          <input value={password} onChange={(e) => this.handleOnChange(e, 'password')} type="password" className="form-control" id="password" placeholder="Mot de passe" required />
        </div>
        <ForgottenPassword />
        <StyledButton type="submit" onClick={(e) => this.checkLogin(e)} isLoading={isLoading} style={{ marginTop: 15 }}>Se connecter</StyledButton>
        {error && <div className="text-danger mt-3">{message}</div>}
      </>
    )
  }

  render() {
    const LoginForm = this.renderForm()
    const { checkout } = this.props

    if (checkout) {
      return LoginForm
    }
    return (
      <PublicPage fullHeight footer={false}
        metaTitle="Login"
        metaDescription="Page login, connectez-vous ici.">
        <div className="row vh-100" style={{ backgroundImage: GLOBAL.gradient }}>
          <div className="col-sm-12 my-auto">
            <form className="card card-block mx-auto bg-light p-4 shadow" style={{ width: 310, maxWidth: '100%' }}>
              <div style={{ color: COLORS.green, fontSize: 24, textAlign: 'center', marginBottom: 15 }}>Connexion</div>
              {/* <div className="form-group">
               <FacebookLogin
                  appId="322540114892623"
                  autoLoad={true}
                  fields="name,email,picture"
                  onClick={() => console.log("toto")}
                  textButton="Se connecter avec Facebook"
                  icon={ <div style={{ width: 20, padding: 5, display: 'inline' }}><FaFacebookF size="20" /></div>}
                  cssClass="facebook"
                />
                <GoogleLogin
                  clientId="975945960032-j987p3mqq446fmj34b8j03r0lq5t66gu.apps.googleusercontent.com"
                  buttonText="Se connecter avec Google"
                  className="google"
                  onSuccess={() => console.log("toto")}
                  onFailure={() => console.log("toto")}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', height: 11, borderBottom: '1px solid #cecece' }} />
                <div style={{ color: '#05b65c', marginLeft: 10, marginRight: 10 }}>OU</div>
                <div style={{ width: '100%', height: 11, borderBottom: '1px solid #cecece' }} />
              </div> */}
              {LoginForm}
              <div style={{ width: '100%', height: 10, marginTop: 30, borderBottom: '1px solid #cecece' }} />
              <div className="d-flex flex-row mt-2">
                <div style={{ width: 150 }}>Vous n'avez pas encore de compte?</div>
                <button onClick={() => this.isAddingParking()} type="button" className="btn btn-light border">S'enregistrer</button>
              </div>
            </form>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default (props) => (
  <Consumer>
    {ctx => <PublicRoute component={withRouter(LoginPage)} {...props} data={ctx} />}
  </Consumer>
);
