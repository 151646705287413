import LandingPage from 'src/views/LandingPage'
import LocationParkingMiddleware, { loadData } from 'src/components/LocationParkingMiddleware';
import SearchPage from 'src/views/SearchPage';
import LoginPage from 'src/views/LoginPage';
import RegisterPage from 'src/views/RegisterPage';
import AddPhonePage from 'src/views/AddPhonePage';
import PhonePage from 'src/views/PhonePage';
import AccountPage from 'src/views/AccountPage/AccountPage';
import AddParkingQuickPage from 'src/views/AddParkingQuickPage';
import ContactPage from 'src/views/ContactPage';
import FaqPage from 'src/views/FaqPage';
import FindParkingSpaces from 'src/views/FindParkingSpaces';
import RentMyParking from 'src/views/RentMyParking';
import CguPage from 'src/views/CguPage';
import ModalitesPage from 'src/views/ModalitesPage';
import DroitsPage from 'src/views/DroitsPage';
import MentionsPage from 'src/views/MentionsPage';
import LegalsPage from 'src/views/LegalsPage';
import EditParkingPage from 'src/views/EditParkingPage';
import MyParkingsPage from 'src/views/MyParkingsPage';
import BankInfosPage from 'src/views/BankInfosPage';
import CreditCardPage from 'src/views/CreditCardPage';
import EstimationPage from 'src/views/EstimationPage';
import CheckoutPage from 'src/views/CheckoutPage'
import BookingsPage from 'src/views/BookingsPage'
import ActionPage from 'src/views/ActionPage'

import NotFound from 'src/components/NotFound';
import Loader from 'src/components/Loader';


export default [
    // PUBLIC PAGES
    { path: '/', component: LandingPage, exact: true },
    { path: '/faq', component: FaqPage, },
    { path: '/contact', component: ContactPage, },
    { path: '/cgu', component: CguPage, },
    { path: '/droits', component: DroitsPage, },
    { path: '/modalites', component: ModalitesPage, },
    { path: '/mentions', component: MentionsPage, },
    { path: '/estimation-parking', component: EstimationPage, },
    { path: '/informations-legales', component: LegalsPage, },
    { path: '/checkout', component: CheckoutPage, },
    { path: '/login', component: LoginPage, },
    { path: '/register', component: RegisterPage, exact: true },
    { path: '/add/:steps?', component: AddParkingQuickPage, },
    { path: '/trouver-un-parking', component: FindParkingSpaces, },
    { path: '/louer-mon-parking', component: RentMyParking, },
    { path: '/search', component: SearchPage, },
    { path: '/location', component: LocationParkingMiddleware, loadData },
    { path: '/action', component: ActionPage, },
    // PRIVATE PAGES
    { path: '/register/phone/confirmation', component: PhonePage, exact: true },
    { path: '/register/phone', component: AddPhonePage },
    { path: '/account/parking/:id/:mode', component: EditParkingPage, },
    { path: '/account/parkings', component: MyParkingsPage },
    { path: '/account/bank-infos', component: BankInfosPage, },
    { path: '/account/cards', component: CreditCardPage, },
    { path: '/account/reservations', component: BookingsPage, },
    { path: '/account', component: AccountPage },

    // NOT FOUND
    { component: NotFound }

];



{/*
<PublicRoute exact path = "/" component = { LandingPage } {...this.props } />
<PublicRoute path = "/faq" component = { FaqPage } />
<PublicRoute path="/contact" component={ContactPage} />
<PublicRoute path="/cgu" component={CguPage} />
<PublicRoute path="/estimation-parking" component={EstimationPage} />
<PublicRoute path="/informations-legales" component={LegalsPage} />
<PublicRoute path="/checkout" component={CheckoutPage} />
<PublicRoute path="/login" component={LoginPage} {...this.props} />
<PublicRoute exact path="/register/phone/confirmation" component={PhonePage} {...this.props} />
<PublicRoute exact path="/register/phone" component={AddPhonePage} {...this.props} />
<PublicRoute exact path="/register" component={RegisterPage} {...this.props} />
<PublicRoute path="/add/:steps?" component={AddParkingQuickPage} {...this.props} />
<PublicRoute path="/trouver-un-parking" component={FindParkingSpaces} />
<PublicRoute path="/louer-mon-parking" component={RentMyParking} />
<PublicRoute path="/search" component={SearchPage} {...this.props} />
<PublicRoute path="/location" component={LocationParkingMiddleware} {...this.props} />
<PublicRoute path="/action" component={ActionPage} {...this.props} />
<PrivateRoute exact path="/account/parking/:id/:mode" component={EditParkingPage} />
<PrivateRoute exact path="/account/parkings" component={MyParkingsPage} />
<PrivateRoute exact path="/account/bank-infos" component={BankInfosPage} />
<PrivateRoute exact path="/account/cards" component={CreditCardPage} />
<PrivateRoute exact path="/account/reservations" component={BookingsPage} />
<PrivateRoute exact path="/account" component={AccountPage} />
<Route component={NotFound} /> */}