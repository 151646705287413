import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TimeIcon from '@material-ui/icons/AccessTime'
import Divider from 'src/components/Divider'
import { RENTAL_MODE } from 'src/services/store/SearchStore';
import StartAndEndPicker from 'src/components/DatePickers/StartAndEndPicker'
import ParkingApi from 'src/api/parking'
import moment from 'moment'
import LoaderSimple from 'src/components/LoaderSimple';
import Tooltip from 'src/components/Tooltip'

function BookingResume({ fullBooking, setFullBooking, isParkingAvailable, setIsParkingAvailable }) {
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(moment(fullBooking.startDate))
  const [endDate, setEndDate] = useState(moment(fullBooking.endDate))

  const onDateSelected = async ({ newStartDate, newEndDate }) => {
    if (fullBooking.rentalMode === RENTAL_MODE.oneshot && newStartDate && newEndDate) {
      setIsLoading(true)
      const res = await ParkingApi.parkingPrices({
        parkingId: fullBooking.parking.id,
        rentalMode: fullBooking.rentalMode,
        startDate: newStartDate ? moment(newStartDate).unix() : null,
        endDate: newEndDate ? moment(newEndDate).unix() : null
      })
      setIsLoading(false)
      if (res && !res.error) {
        setFullBooking({
          ...fullBooking,
          startDate: newStartDate,
          endDate: newEndDate,
          bookingPrices: {
            parkingPrice: res.displayed_price,
            serviceFee: res.service_fee,
            totalPrice: res.total_price,
          }

        })
        setIsParkingAvailable(res.available)
      }
    }
  }

  return (
    <Grid container className="px-2">
      <Grid item xs={12} style={{ fontSize: '1.2rem', fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
        Détails de la demande
    </Grid>
      {fullBooking.rentalMode === RENTAL_MODE.monthly &&
        <Grid item xs={12} className="my-2" style={{ display: 'flex', justifyContent: 'center' }}>
          <TimeIcon style={{ fontSize: 25 }} />
          <span style={{ fontSize: '1.1rem', marginLeft: 15 }}>
            Abonnement mensuel
      </span>
        </Grid>
      }
      {(fullBooking.rentalMode === RENTAL_MODE.oneshot && isParkingAvailable) &&
        <Grid item xs={12} className="my-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'around', alignItems: 'center' }}>
          <TimeIcon style={{ fontSize: 25 }} />
          <StartAndEndPicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} onDateSelected={onDateSelected} />
        </Grid>
      }
      {!isParkingAvailable &&
        <Tooltip color="yellow" text="Le parking n'est pas disponible pour la période demandée." />
      }
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <span style={{ fontSize: '1.1rem' }}>
          {fullBooking.parking.address}, {fullBooking.parking.city}
        </span>
      </Grid>
      <GridDivider />
      <PriceLine isLoading={isLoading} title="Parking" price={fullBooking.bookingPrices.parkingPrice || '-'} />
      <PriceLine isLoading={isLoading} title="Frais de service" price={fullBooking.bookingPrices.serviceFee || '-'} />
      <GridDivider />
      <PriceLine isLoading={isLoading} title="Total" price={fullBooking.bookingPrices.totalPrice || '-'} />
    </Grid>
  )
}

const GridDivider = () => (
  <Grid item xs={12} className="px-3">
    <Divider />
  </Grid>
)

const PriceLine = ({ title, price, isLoading }) => (
  <Grid item xs={12}
    className="px-3"
    style={{ fontSize: '1rem', display: 'flex', justifyContent: 'space-between' }}>
    <div>
      {title}
    </div>
    <div className='d-flex flex-row'>
      {isLoading && <LoaderSimple height={25} width={25} />}
      {!isLoading && price} €
    </div>
  </Grid>
)

export default BookingResume
