import React from 'react'
import PublicRoute from 'src/containers/PublicRoute';
import PublicPage from 'src/templates/PublicPage';
import OutlinedButton from 'src/components/OutlinedButton';


import './style.css'


class FaqPage extends React.Component {

  render() {
    return (
      <PublicPage navbarPush
        metaTitle="Foire aux questions"
        metaDescription="Questions les plus fréquement posées sur le service Parkmatch. Location de parking et garage entre particuliers."
      >
        <div className="row min-vh-100" style={{ backgroundColor: 'white' }}>
          <div className="col">
            <div className="row py-5 gradient-header">
              <div className="col-md-6 py-4 offset-md-3 text-center title-faq">
                <h1>FAQ</h1>
                <h2>Foire aux questions !</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                <style>
                  {`
                  h2, h4, h5 {
                    font-weight: bold;
                    margin-top: 10px;
                  }
                  
                  h2 { margin-top: 40px; }
                  
                  h4 { margin-top: 40px; }
                  `}
                </style>
                <h2>1. Compte et information</h2>

                <h5>i. Comment créer un compte sur Parkmatch</h5>
Pour créer un compte, vous devez renseigner un nom, un prénom, un email et un mot de passe puis valider votre numéro de téléphone depuis le site www.parkmatch.eu.
Vous pourrez ensuite utiliser le service pour mettre un parking en location ou trouver un parking.

                <h5>ii. J'ai oublié mon mot de passe ou n'arrive pas à me connecter</h5>
                Il y a une procédure de mot de passe oublié qui permet de mettre à jour son mot de passe. Cette procédure est accessible depuis l'interface de connexion sur l'application ou sur le site Internet. Si toutefois des problèmes persistent, vous pouvez formuler une demande en expliquant votre problème via le chat ou à l'adresse support@parkmatch.eu.

                <h5>iii. Comment supprimer mon compte ?</h5>
                Pour supprimer votre compte, vous pouvez envoyer une demande de suppression à contact@parkmatch.eu ou privacy@parkmatch.eu.

                <h5>iv. Que faire si je n'arrive pas à me connecter à mon compte ?</h5>
                En cas de problème de connexion, vous avez la possibilité de réinitialiser votre mot de passe ou de joindre le support via le chat ou à l'adresse support@parkmatch.eu.

                <h5>v. Comment modifier mes informations personnelles ou documents ?</h5>
                À tout moment, vous pouvez modifier vos informations personnelles dans l'onglet "Mon compte" sur le site Internet ou sur l'application. Concernant les documents, vous pouvez également les mettre à jour en vous rendant dans la section appropriée.

                Vous pouvez également demander la suppression de vos données personnelles par mail à privacy@parkmatch.eu.

                <h5>vi. Moyen de paiement et exactitude des données personnelles</h5>

                Pourquoi dois-je payer avec un moyen de paiement à mon nom et prénom et renseigner mes informations personnelles exactes sur Parkmatch ?
                Afin de garantir une expérience fluide et sécurisée sur Parkmatch, notre partenaire de paiement impose un certain nombre de règles.
                Il est donc nécessaire d'indiquer des informations personnelles valides et d'utiliser un moyen de paiement correspondant afin de bénéficier du service. Dans le cas contraire, l'accès au service de location de parking vous sera refusé.

                <h5>vii. Comment mon compte est-il sécurisé ?</h5>
                Vos données personnelles sont conservées sur un serveur sécurisé et votre mot de passe nous arrive crypté. Vous seul en connaissez le secret.
                Les coordonnées bancaires sont hébergées via notre partenaire bancaire Lemonway qui assure la confidentialité et la sécurité des informations via BNP PARIBAS.
                Pour plus d'information, vous pouvez consulter les conditions générales d'utilisation de Lemonway directement dans nos conditions générales d'utilisation disponibles sur le site

                <h5>viii. Comment contrôler l’utilisation de mes données personnelles ?</h5>

                Quelles sont les informations que je partage avec Parkmatch et comment puis-je contrôler l’utilisation de mes données personnelles ?
                Vous partagez avec Parkmatch toutes les informations nécessaires à l'utilsiation du service. Vous pouvez à tout moment demande la suppression ou la modification de vos données par mail à contact@parkmatch.eu.
                Pour plus d'information, vous pouvez également consulter notre charte de confidentialité des données au lien suivant:
                https://www.parkmatch.eu/informations-legales
                <h5>ix. Comment puis-je vérifier mon numéro de téléphone ?</h5>

                Vous pouvez vérifier votre téléphone en renseignant votre numéro. Un code de validation vous sera envoyé par SMS. Vous n'avez plus qu'à le rentrer dans le champ de validation pour que votre téléphone soit vérifié.
                Si vous ne recevez pas le code ou vous ne parvenez pas à vérifier votre téléphone, nous vous invitons à écrire un mail à support@parkmatch.eu afin d'obtenir de l'aide.


                <h2>2. Propriétaire</h2>

                <h4>a. Avant de mettre son parking sur Parkmatch</h4>

                <h5>i. En quoi consiste le service ?</h5>
                Parkmatch propose un service de réservation de parking entre particuliers qui permet de mettre en location son parking en toute simplicité. A travers le site, un propriétaire peut gérer la disponibilité de son parking, ses revenus.
                Le service Parkmatch s'adresse exclusivement aux annonceurs particuliers : toute personne physique, majeure, agissant exclusivement à des fins privées et non professionnelles.
                Les annonceurs professionnels ont interdiction formelle de communiquer sur Parkmatch.

                <h5>ii. Quels sont les avantages du service Parkmatch ?</h5>
                Parkmatch simplifie la gestion d'une location de parking et permet une meilleure rentabilité en utilisant les options de location courte durée. Plus de paperasse, plus de contraintes, tout est géré via Parkmatch.

                <h5>iii. Faut-il un accord de la copropriété pour mettre en location ?</h5>
                Pour louer son parking, il faut être en accord avec son règlement de copropriété, c'est à dire que celui-ci autorise la location de ses biens privés. Aucun accord spécifique à l'utilisation du service Parkmatch est nécessaire.
                iv.	Quelles conditions dois-je remplir pour proposer un parking ?
                Afin de mettre un parking en location sur Parkmatch, vous devez respecter nos conditions générales d'utilisation, disponibles au lien suivant:
                https://www.parkmatch.eu/cgu

                <h5>iv. Quels types de parkings puis-je proposer sur Parkmatch ?</h5>
                Vous pouvez proposer exclusivement des parking (à l’exclusion de garages ou boxes), en intérieur ou extérieur et avec tous types d'accès.
                Nous recommandons toutefois que l'accès au parking soit sécurisé (par un portail, barrière ou autre) afin de garantir la disponibilité de celui-ci lors de l'arrivée d'un locataire.


                <h5>v. Je suis locataire, puis-je sous-louer mon parking ?</h5>
                Vous pouvez tout à fait mettre un parking en sous-location sur Parkmatch, sous réserve que votre bail vous en donne le droit ou que vous ayez un accord écrit du bailleur pour le faire.
                <h5>vi. Que dois-je fournir pour utiliser Parkmatch ?</h5>
                L'ensemble des informations à fournir sont détaillées dans notre charte de confidentialité des données disponibles au lien suivant.
                https://www.parkmatch.eu/informations-legales
                <h5>vii. Faut-il signer un contrat de location avec le locataire ?</h5>
                Non, il n'est pas obligatoire de signer un contrat de location ou type bail. L'acceptation des conditions générales d'utilisation et l'utilisation du service remplacent le contrat classique et permettent ainsi une grande flexibilité.
                <h5>viii. Suis-je engagé(e) lorsque mon parking est sur Parkmatch ?</h5>
                Non, il n'y a aucun engagement, vous pouvez gérer à tout moment la disponibilité de votre parking sur Parkmatch.
                Lorsque votre parking est loué en abonnement, vous pouvez demander la résiliation pour récupérer votre parking avec 30 jours de préavis.
                Nous vous recommandons de maintenir à jour la disponibilité de votre parking afin de vous garantir une expérience optimale ainsi qu'aux membres de la communauté.
                <h5>ix. Parkmatch est-il gestionnaire de ma place de parking ?</h5>
Non, en aucun cas Parkmatch est gestionnaire de votre parking.

Vous gardez la gestion de votre parking et avez la main pour contrôler la disponibilité, les prix et les demandes de réservation. Parkmatch apporte des services de mise en relation et des outils facilitant le paiement de vos loyers.

En tant que propriétaire bailleur, vous assurez : 

-        La visite de votre parking aux futurs locataires
-        La sélection de votre locataire
-        La remise des accès à votre locataire
-        La constitution du dossier de location : justificatif de revenus et d’identité de votre locataire, justificatif d’assurance de son véhicule
-        La signature d’un contrat de location spécifique (non indispensable car l'acceptation des conditions générales d'utilisation et l'utilisation du service remplacent le contrat classique et permettent ainsi une grande flexibilité)
-        La gestion des impayés et dégradations commise par votre locataire
-        L'état des lieux de début et de fin de location
-        La récupération des clés auprès de votre locataire en fin de location
-        L’émission et la transmission des quittances de loyer à votre locataire

                <h4>b. Enregistrer son parking</h4>

                <h5>i. Comment enregistrer mon parking sur Parkmatch ?</h5>

                Une fois votre compte créé et validé sur Parkmatch, vous pouvez ajouter votre parking directement depuis le site Internet en cliquant sur le bouton "Proposer mon parking" ou depuis le menu "Mes parkings" dans la rubrique mon compte.
                De même, sur l'application vous pouvez dans le menu "Parkings" ajouter un parking en utilisant le bouton "+". Vous devrez ensuite renseigner l'ensemble des informations permettant de valider et diffuser votre parking sur la plateforme.

                <h5>ii. Puis-je mettre mon parking en location ponctuellement ?</h5>
                Oui, lors de l'enregistrement de votre parking sur Parkmatch, vous pouvez choisir le type de réservation (longue durée, courte durée ou les deux) que vous acceptez. Vous pouvez à tout moment modifier vos préférences dans le menu "Parkings" en cliquant sur le parking concerné.

                <h5>iii. Pourquoi je ne vois pas mon parking sur la carte ?</h5>
                Afin de rendre votre parking visible sur l'application, il faut bien penser à publier votre parking (étape finale) et à bien mettre à jour la disponibilité de celui-ci.
                Un bouton on/off vous premet de gérer la disponibilité de votre parking en toute simplicité dans le menu d'édition du parking sur le site Internet.
                Sur l'application il y a également un bouton en bas de page parking qui vous permet de rendre votre parking visible (disponible) ou invisible (indisponible) sur le réseau Parkmatch.
                Lors que votre parking est réservé en abonnement (longue durée), celui-ci disparaît automatiquement de la carte. Si votre parking est réservé en courte durée, celui-ci sera invisible uniquement sur la période réservée.

                <h5>iv. Comment changer la disponibilité de mon parking ?</h5>

                À tout moment, vous pouvez mettre à jour la disponibilité de votre parking dans le menu "Parkings" sur le site ou l'application.
                Nous vous recommandons de bien tenir à jour la disponibilité de votre parking afin de vous garantir une expérience maximale ainsi qu'aux autres membres de la communauté. Lorsque votre parking est disponible, vous pouvez recevoir des demandes de réservation.
                Après plusieurs réservations non confirmées ou après un mois d'inactivité sur la plateforme, votre parking sera automatiquement passé en indisponible.


                <h5>v. Pourquoi mon parking est visible sur d'autres sites ?</h5>

                Lorsque vous proposez un parking sur Parkmatch, nous le rediffusons auprès de notre réseau de partenaires afin de trouver des intéressés en un temps record. Cela garantit une plus grande visibilité à votre parking et vous donne ainsi la possibilité de trouver un locataire plus rapidement.
                Vous pouvez retrouver toutes les informations concernant la gestion de vos données personnelles dans notre politique de confidentialité présentée lors de l'inscription sur Parkmatch ou disponible au lien suivant:
                https://www.parkmatch.eu/informations-legales
                Pour toute demande concernant vos données personnelles, vous pouvez envoyer une demande à privacy@parkmatch.eu.

                <h5>vi. Comment supprimer mon parking sur Parkmatch ?</h5>
                Vous pouvez à tout moment désactiver la disponibilité de votre parking dans le menu "Mes parkings". Il sera alors invisible sur la carte et n'apparaîtra plus en résultat de notre moteur de recherche.
                Vous pouvez également supprimer définitivement votre annonce de parking dans ce même menu.

                <h4>c. Prix de location</h4>

                <h5>i. Pourquoi le prix affiché sur la carte est différent ?</h5>
                Parkmatch applique des frais de service au locataire, c'est pourquoi le prix affiché diffère du prix de location. Pour consulter le détail des modalités tarifaires, vous pouvez vous rendre dans la section dédiée.

                <h5>ii. Comment choisir le prix de location pour mon parking ?</h5>
                Afin de maximiser vos chances d'avoir des réservations sur votre parking nous vous recommandons de maintenir la disponibilité de votre parking à jour.

                Vous restez toutefois libre de choisir votre prix pour être plus compétitif que le marché ou au-dessus de celui-ci.

                <h4>d. Gestion des demandes de location</h4>


                <h5>i. J'ai reçu une demande de réservation en abonnement</h5>

                Lorsque vous recevez une demande de réservation en abonnement mensuel nous vous invitons à prendre contact avec le potentiel locataire pour organiser la visite du parking.
                Lors de la visite, si le parking convient, vous pouvez lui remettre les accès au parking en échange de son code à 4 chiffres que vous entrez dans le champ de validation d'abonnement sur le parking correspondant dans le menu "Parkings".
                Vous recevez également le premier versement pour le mois. Par la suite, il y a une reconduction tacite de l'abonnement avec une possibilité de résiliation pour les deux parties avec 30 jours de préavis.

                <h5>ii. J'ai reçu une demande de réservation ponctuelle</h5>
                Lorsque vous recevez une demande de réservation ponctuelle, vous avez les indications du locataire sur sa réservation ainsi que la date et heure d'arrivée et de départ. Vous avez donc les éléments pour accepter ou refuser la demande de réservation.
                Une fois la demande acceptée, vous recevrez le contact du locataire pour organiser avec lui la remise des accès au parking. Vous recevrez le paiement de la réservation sur votre compte Parkmatch 48h après la fin de la réservation.

                <h4>e. Déroulement d'une location</h4>

                <h5>i. Comment démarrer un abonnement mensuel sur un parking ?</h5>
                Nous vous invitions à vous rendre dans la section "J'ai reçu une demande de réservation en abonnement" pour comprendre comment démarrer la réservation mensuelle sur votre parking.

                <h5>ii. Dois-je récupérer d'autres documents du locataire ?</h5>
                Non, mis à part la récupération du code de validation pour la réservation en abonnement mensuel, il n'est pas obligatoire de récupérer d'autres documents.
                Vous êtes néanmoins totalement libre de demander à votre locataire des justificatifs tels que sa pièce d’identité, justificatif de domicile avant d’accepter de lui louer votre parking.

                <h5>iii. Comment donner les accès au parking ?</h5>
                La remise d'accès s’effectue en main propre en fixant un rendez-vous avec le locataire ou en lui donnant les indications nécessaires à la récupération des clefs.


                <h5>iv. Comment résilier l'abonnement sur mon parking ?</h5>
                Vous pouvez résilier l'abonnement sur votre parking à tout moment dans le menu "Parkings" en cliquant sur le bouton "Résilier".

                Il y aura alors une période de préavis de 30 jours à la fin de laquelle vous devrez vous organiser avec le locataire pour la récupération des accès.

                Nous vous recommandons avant d'effectuer cette procédure d'en informer le locataire par téléphone afin de vous organiser au mieux.

                <h5>v. Comment contacter le locataire ?</h5>
                Dans le cas d'une demande de réservation, vous avez accès immédiatement au contact du locataire intéressé dans le menu de réservation du parking concerné.

                <h4>f. En cas de problème</h4>

                <h5>i. Que faire en cas de problème dans la restitution des accès ?</h5>
                En cas de problème dans la récupération des accès après une réservation effectuée sur Parkmatch, vous avez 24h après la fin de la réservation pour déclarer un problème.

                Le lien est disponible dans le mail de confirmation de fin de réservation. Le service client prendra alors le relais pour répondre au mieux à votre demande et étudier avec vous les potentielles solutions.

                <h5>ii. Comment résoudre un litige avec le locataire ?</h5>
                Nous vous recommandons d'informer immédiatement le service client d'un litige avec le locataire à l'adresse support@parkmatch.eu. Celui-ci vous viendra en aide pour tenter de régler le litige dans les meilleurs délais.

                <h4>g. Paiements, taxes et revenus</h4>

                <h5>i. Sous combien de temps je reçois mes revenus sur Parkmatch ?</h5>
                Dans le cadre d'une réservation en abonnement mensuel sur votre parking, vous aurez le premier versement pour le mois à la validation de l'abonnement. Puis vous recevrez à date d'échéance avec tacite reconduction le paiement des revenus des mois suivants.
                Dans le cadre d'une réservation ponctuelle, vous recevrez les revenus de la location de votre parking sur votre compte Parkmatch 48h après la fin de la réservation.

                <h5>ii. Comment transférer mes revenus sur mon compte bancaire ?</h5>
                En tant que particulier, vous devez fournir :
                •	Deux justificatifs d’identité : un passeport et une carte nationale d'identité (recto et verso sur le même document)
                •	Un RIB au même nom pour valider votre compte bancaire et pouvoir ainsi transférer vos revenus Parkmatch.
                Attention, le nom de votre compte Parkmatch doit correspondre au nom présent sur les documents pour que ceux-ci soient validés par notre partenaire.

                <h5>iii. Quel délai pour avoir mes revenus sur mon compte bancaire ?</h5>
                En général, le virement de vos revenus Parkmatch vers votre compte bancaire prend entre 3 et 5 jours ouvrés selon votre établissement bancaire.

                <h5>iv. Y a-t-il des frais pour virer mes revenus sur mon compte ?</h5>
                Non, il n'y a aucun frais de versement mais seulement un minimum de versement de 20 euros.

                <h5>v. Y a-t-il des frais d'utilisation du service ?</h5>
                Parkmatch se rémunère par commission sur les revenus perçus pour les réservations et facture des frais de service aux locataires. Nous vous invitons à consulter nos modalités tarifaires dans la section dédiée.

                <h5>vi. Où puis-je trouver mon reçu de réservation ?</h5>
                Votre justificatif de réservation vous est transmis automatiquement par mail à chaque réservation ponctuelle ou à chaque échéance reconduite en réservation type abonnement mensuel sur votre parking.

                <h5>vii. Comment gérer la taxe d'habitation sur votre parking ?</h5>
                Dans certaines situations, les Utilisateurs sont susceptibles d’être redevables de la taxe d’habitation dans les conditions des articles 1407 et suivants du Code Général des Impôts. Le cas échéant, les Utilisateurs s’engagent à s’acquitter de leurs obligations fiscales en la matière.


                <h5>viii. Quelle est la commission Parkmatch appliquée au propriétaire ?</h5>
Pour les réservations en abonnement, une commission de 5% est prélevée sur le revenu mensuel du propriétaire. Pour les réservations ponctuelles, une commission de 7,5% est prélevée sur le revenu du propriétaire.

                <h2>3. Locataire</h2>

                <h4>a. Avant de réserver un parking sur Parkmatch</h4>

                <h5>i. En quoi consiste le service ?</h5>
                Parkmatch propose un service de réservation de parking entre particuliers qui permet la mise en relation les propriétaires de parking et les locataires et le paiement des loyers par le locataire.
                Vous pouvez réserver un parking que ce soit à la journée, semaine, mois ou à l'année avec différents modes de remise d'accès.


                <h5>ii. Quels sont les avantages ParkMatch ?</h5>
                Parkmatch propose une interface qui permet de trouver rapidement un parking qui correspond à ses critères et pour la durée souhaitée.
                De plus, en passant par Parkmatch, les paiements sont gérés, les contrats automatisés de façon simplifiée via des conditions générales d'utilisation.
                
                <h5>iii.	Quels types de parkings sont disponibles sur Parkmatch ?</h5>
                Vous trouverez tous types de parking (à l’exclusion de garage ou box), en intérieur ou en extérieur sur Parkmatch. Vous pouvez filtrer votre recherche à partir des critères de taille de véhicule notamment pour être assuré de trouver un parking qui répond à vos besoins.
                
                <h5>iv.	Comment trouver un parking sur Parkmatch ?</h5>
                Pour trouver un parking sur Parkmatch, il faut se rendre sur le site parkmatch.eu et effectuer une recherche. Avant même la création de compte, vous pouvez voir les parkings enregistrés autour de votre position.
                
                <h5>v.	Puis-je louer un parking à la journée ?</h5>
                Oui, il est possible de louer un parking pour la durée souhaitée à partir d'une journée.
                
                <h5>vi.	Faut-il signer un contrat de location avec le propriétaire ?</h5>
                Les conditions générales d'utilisation et l'utilisation du service suffisent à cadrer la réservation de parking entre particuliers. Toutefois, le propriétaire de parking peut être amené à vous demander des justificatifs complémentaires tels qu’une copie de votre pièce d’identité ou un justificatif de domicile et la signature d’un contrat de location.
                
                <h5>vii.	Puis-je visiter le parking avant de le réserver ?</h5>
                Dans le cadre d'une réservation en abonnement mensuel sur un parking, vous avez la possibilité d'envoyer un message pour préciser votre demande. Une fois la demande envoyée (ce qui ne valide pas l'abonnement), vous avez accès au contact du propriétaire avec qui vous pouvez organiser une visite du parking. Ce n'est qu'après si vous décidez de concrétiser votre réservation en abonnement mensuel que le premier paiement est effectué.
                Dans le cadre d'une réservation ponctuelle, vous aurez la possibilité d'échanger avec le propriétaire du parking avant de formuler définitivement une demande de réservation. Ce n'est qu'après validation de la réservation par le propriétaire que vous aurez son contact définitif pour organiser l'arrivée sur place.


                <h5>viii. Vous cherchez un parking ?</h5>

                Pour rechercher un parking, vous devez vous rendre sur la plage d'accueil de Parkmatch et utiliser le moteur de recherche.
                <h4>b. La location</h4>

                <h5>i. Comment faire une demande de réservation en abonnement ?</h5>
                Pour faire une demande de réservation en abonnement mensuel sur un parking Parkmatch, il faut tout d'abord créer un compte.
                Après avoir trouvé le parking de votre choix, vous devez enregistrer un moyen de paiement et un véhicule. Vous pouvez envoyer ensuite votre demande. Aucun paiement ne sera déclencher.
                Une fois la demande envoyée, vous avez accès au contact du propriétaire pour organiser une visite du parking. Si celui-ci vous convient, vous pourrez lui remettre votre code à 4 chiffres reçu avec la demande de réservation, seulement en échange des accès. Cette dernière étape confirme le démarrage de l'abonnement sur le parking et valide le premier versement pour le mois. L'abonnement est ensuite reconduit tacitement à date d'échéance avec un prélèvement récurrent sur votre carte bancaire.
                
                <h5>ii.	Comment faire une demande de réservation courte durée ?</h5>
                Pour faire une demande de réservation ponctuelle sur Parkmatch, il vous faut tout d'abord créer un compte.
                Après avoir trouvé le parking de votre choix, vous envoyer une demande de réservation avec le maximum d'information afin que le propriétaire puisse avoir l'ensemble des éléments lui permettant d'accepter ou de refuser votre demande.
                Votre moyen de paiement ne sera débité qu'après acceptation du propriétaire. Vous aurez alors accès à son contact pour organiser l'arrivée sur place et la remise des accès.
                
                <h5>iii.	Comment contacter le propriétaire ?</h5>
                Dans le cadre d'une demande de réservation en abonnement, vous aurez accès au numéro du propriétaire après envoi de la demande. Vous pourrez alors lui poser l'ensemble des questions relatives à son parking et éventuellement organiser une visite et confirmer votre abonnement.
                Une fois la demande envoyée, vous retrouverez les coordonnées du propriétaire dans le mail de confirmation et également dans le menu "Mes réservations" sur le site.
                Dans le cadre d'une réservation ponctuelle, vous aurez accès au numéro du propriétaire après acceptation de votre réservation. Vous trouverez alors le numéro dans l'onglet "Mes réservations" sur le site.
                
                <h5>iv.	Comment récupérer les accès au parking ?</h5>
                La remise d'accès s’effectue en main propre en fixant un rendez-vous avec le locataire ou en lui donnant les indications nécessaires à la récupération des clefs.

                <h5>v. Comment résilier mon abonnement sur un parking ?</h5>
                Vous pouvez à tout moment résilier votre abonnement sur un parking Parkmatch avec un préavis de 30 jours. A la fin de cette période, vous devrez vous organiser avec le propriétaire pour lui remettre les accès au parking.
                Des pénalités et sanctions peuvent être appliquées en cas de retard ou de dégradation des accès.

                <h5>vi. Dois-je remettre d'autres documents au propriétaire ?</h5>

                Le propriétaire de parking peut être amené à vous demander des justificatifs complémentaires tels qu’une copie de votre pièce d’identité ou un justificatif de domicile.

                <h4>c. Paiement</h4>

                <h5>i. Quels moyens de paiement sont acceptés ?</h5>
                Les paiements par carte bancaire CB, Visa, Mastercard sont acceptés sur la plateforme.

                <h5>ii. Y a-t-il des frais d'utilisation du service ?</h5>
                Parkmatch facture des frais de service pour l'utilisation de la plateforme, directement indexés sur le prix de réservation. Vous pouvez consulter le détail des conditions tarifaires dans la section dédiée.

                <h5>iii. Où puis-je trouver mon justificatif de paiement ?</h5>
                A chaque réservation ponctuelle ou à chaque échéance d'abonnement, vous recevez par mail votre justificatif de paiement. En cas de besoin, vous pouvez contacter le support si nécessaire.

                <h5>iv. Qu'est ce qui est inclus dans le prix de réservation ?</h5>
                Le prix de réservation final inclut la location du parking et les frais de service (plateforme de mise en relation, paiement des loyers).
                v.	Puis-je payer directement le propriétaire ?
                Après une mise en relation, nous vous déconseillons fortement de passer en direct avec le propriétaire. En effet, vous perdez d'une part les avantages que propose Parkmatch. Vous vous exposez également à des risques de fraude et d'insécurité.

                <h5>vi. Êtes-vous redevable de la taxe d'habitation ?</h5>
                Dans certaines situations, les Utilisateurs sont susceptibles d’être redevables de la taxe d’habitation dans les conditions des articles 1407 et suivants du Code Général des Impôts. Le cas échéant, les Utilisateurs s’engagent à s’acquitter de leurs obligations fiscales en la matière.

                <h5>vii. Quels sont les frais de service imputés au locataire ?</h5>
                Pour les réservations en abonnement, des frais de service de 4,5% du prix mensuel sont imputés au locataire.
                Pour les réservations ponctuelles, des frais de service de 7,5% du prix sont imputés au locataire.

                <h4>d. Autres</h4>

                <h5>i. Comment résoudre un litige avec le propriétaire ?</h5>
                Nous vous recommandons d'informer immédiatement le service client d'un litige avec le propriétaire à l'adresse support@parkmatch.eu.
                Celui-ci vous viendra en aide pour tenter de régler le litige dans les meilleurs délais.
                
                <h5>ii.	Que faire si je ne trouve pas le parking en arrivant ?</h5>

                Une fois une demande de réservation ponctuelle confirmée, si vous ne parvenez pas à trouver le parking ou à rencontrer le propriétaire pour récupérer les accès, nous vous invitons à formuler une demande au plus vite à support@parkmatch.eu.
                En cas d'impossibilité d'accéder au parking et sans possibilité de joindre le propriétaire, nous vous invitons à déclarer un problème dans les 12h suivant le début de votre réservation. Passé ce délai, l'ensemble des charges dues seront exigibles et ne pourront faire l'objet d'un remboursement.


                <h5>iii. Quelles sont les conditions de remboursement ?</h5>

                Les demandes de remboursement doivent être formulées selon les cas décrits dans les conditions générales d'utilisation et seront ensuite étudiées au cas par cas par notre service client qui reviendra vers vous dans les plus brefs délais.

                <h5>iv. Puis-je annuler une réservation ponctuelle ?</h5>
                Il est possible d'annuler une demande de réservation en abonnement ou ponctuelle tant que celle-ci n'a pas été validée. Pour plus d'information sur les conditions d'annulation, nous vous invitons à vous reporter à l'article correspondant des conditions générales d'utilisation.
                Il n'est pas possible d'annuler une réservation ponctuelle confirmée. En cas de problème, vous devrez formuler une demande au service client à support@parkmatch.eu qui étudiera votre demande.

                <h5>v. Que faire si le propriétaire ne répond pas ?</h5>

                Après avoir envoyé une demande de réservation en abonnement sur Parkmatch, vous avez accès au contact du propriétaire pour organiser une visite. Si celui-ci ne répond pas, nous vous suggérons d'envoyer de nouvelles demandes pour optimiser vos chances de trouver un parking disponible. La demande non aboutie sera automatiquement annulée après un certain laps de temps.
                Dans le cas d'une demande de réservation ponctuelle, vous aurez accès au contact du propriétaire seulement après son acceptation de la demande. Si vous ne parvenez pas à joindre le propriétaire après acceptation de votre demande de réservation, merci de déclarer un problème sur le site Internet ou d'envoyer une demande à support@parkmatch.eu.

                <br/>
                <br/>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default () => <PublicRoute component={FaqPage} />
